import React, { useState } from "react";
import { Collapse, Button,Typography } from "antd";
import Container from "../../../../style/styledComponent/Container";
import { fadein } from "../../../../style/keyframes";
import partnerIcon from '../../../../asesst/new/icon/services.svg';

const { Title, Text } = Typography;
const { Panel } = Collapse;

interface FAQItem {
    question: string;
    answer: string;
}

const allFaqData: FAQItem[] = [
    {
        question: "What is GeoSquare.ai?",
        answer:
            "GeoSquare.ai is a precision geospatial platform that provides highly detailed, location-specific data down to 5x5 meter squares. It allows businesses to access only the data they need for specific areas.",
    },
    {
        question: "Who can benefit from using GeoSquare.ai?",
        answer:
            "Industries such as banking, retail, real estate, logistics, and urban planning benefit from GeoSquare.ai’s precise location data, enabling targeted, data-driven decisions.",
    },
    {
        question: "How does GeoSquare.ai provide data at such a granular level?",
        answer:
            "GeoSquare.ai integrates data from varied sources such as mobile data, satellite imagery, government reports, third-party datasets, community-sourced data, and other available online data. Built by experienced geospatial data scientists and backed by insights from SMEs and academia, our platform uses advanced layering and AI-driven spatial distribution to deliver high-resolution insights within each square.",
    },
    {
        question: "What products does GeoSquare.ai offer?",
        answer:
            "GeoSquare.ai offers a range of products tailored to meet different location intelligence needs:\n" +
            "- **Raw Data**: Provides square-level data organized by themes allowing users to analyze specific metrics for any area.\n" +
            "- **Location Profile**: Summarizes key information about a location, using a reachability analysis to define coverage as base, helping users understand the local environment around a chosen area.\n" +
            "- **Location Scoring**: Offers a favorability score (0-100%) to show how suitable a location is for specific business needs. Scores can be customized; for example, a coffee shop might focus on foot traffic, while a fried chicken franchise may prioritize food consumption.\n" +
            "- **Correlation Analysis**: Helps identify what spatial factors contribute most to store success, making it easier to choose strong locations for future expansion.",
    },
    {
        question: "What data themes are available?",
        answer:
            "GeoSquare.ai offers data on demographics, people traffic, reachability, food consumption, POI analysis, and public datasets like air pollution. Users can tailor insights to their specific area of interest.",
    },
    {
        question: "What areas does GeoSquare.ai cover?",
        answer: "GeoSquare.ai provides data for all locations across Indonesia, with all data themes available nationwide.",
    },
    {
        question: "How frequently is GeoSquare.ai’s data updated?",
        answer:
            "GeoSquare.ai provides data updates on a semester basis to ensure accuracy for time-sensitive decision-making. Some dynamic metrics, such as foot traffic, may receive additional updates as needed. For more real-time updates, please contact our team—note that pricing may be higher for this option.",
    },
    {
        question: "How do I know if the data has been recently updated?",
        answer: "Each dataset in GeoSquare.ai includes a timestamp indicating the last update, ensuring transparency in data recency for users.",
    },
    {
        question: "How does pricing work?",
        answer:
            "Pricing is based on the number of squares selected and the data themes chosen. Each theme has its own rate, and specific variables may vary in price, up to IDR 25 per square, ensuring cost-effectiveness by allowing users to pay only for the data they need.",
    },
    {
        question: "How can I make a payment on GeoSquare.ai?",
        answer:
            "GeoSquare.ai currently accepts payments via virtual account, supported by Xendit. For API purchases, users will need to have GeoSquare credits in their account, which can be purchased through the platform.",
    },
    {
        question: "Can I try GeoSquare.ai before purchasing data?",
        answer:
            "The platform is free to explore, and we also provide public data that is free to acquire by registered users, allowing them to test the square-based concept.",
    },
    {
        question: "Is GeoSquare.ai compatible with other tools?",
        answer:
            "Yes, GeoSquare.ai’s platform-agnostic data integrates seamlessly with any GIS, BI, or analytics tool. We also provide API integration, allowing users to purchase and retrieve data directly into their own platforms.",
    },
    {
        question: "How do I get started with GeoSquare.ai?",
        answer:
            "Users can define their area of interest by searching, dropping a pin, or drawing a custom area. They can then select data themes like demographics or reachability. API integration is also available for direct data access within existing platforms, making it easy to start using GeoSquare.ai’s insights.",
    },
    {
        question: "What type of support is available for GeoSquare.ai users?",
        answer:
            "GeoSquare.ai offers an AI-powered chatbot to help answer questions, recommend products, and create personalized Location Scoring. For further assistance, you can also contact us directly at marketing@geosquare.ai.",
    },
];


const SectionFAQ: React.FC = () => {
    const [visibleFaqCount, setVisibleFaqCount] = useState(6); // Initially show 6 FAQs

    const loadMoreFaqs = () => {
        if (visibleFaqCount < allFaqData.length) {
            const newCount = Math.min(visibleFaqCount + 4, allFaqData.length);
            setVisibleFaqCount(newCount);
        } else {
            // If all FAQs are shown, reset to the initial state
            setVisibleFaqCount(6);
        }
    };

    return (
        <Container
            css={{
                width: "100%",
                padding: "1rem 10rem", // Adjust padding for small vs large screens
                backgroundColor: "#F7F9FC",
                display: "flex",
                flexDirection: "column",
                animation: `1s ease-in 1s 1 both ${fadein}`,

            }}
        >
            <div
            style={{
                marginTop:"120px",
                marginBottom:"120px",
            }}
            >

         
            <div style={styles.integrationHeader}>
                <img alt="Partner Icon" src={partnerIcon} style={styles.icon} />
                <Text style={styles.integrationText}>Question</Text>
            </div>
            <Title style={styles.title}>Frequently asked questions</Title>
            <Title style={styles.subtitle}>
            Explore our frequently asked questions to learn more about OpenIn's features, security, integration capabilities, and more
            </Title>
            
                {allFaqData.slice(0, visibleFaqCount).map((item, index) => (
                     <div key={index.toString()} style={{ marginBottom: "10px" }}>
                    <Collapse
                    accordion
                    expandIconPosition="right" // Set icon position to the right
                    style={{
                        backgroundColor: "#ffffff", // Collapse background color
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Add shadow
                        borderRadius: "8px", // Optional for rounded corners
                        padding: "16px", // Optional inner padding
                        border: "none", // Remove outer border
                        borderBottom: "none", // Remove divider between panels
                      }}
                >
                    <Panel 
                        header={<span 
                            style={{
                                fontFamily: "'Plus Jakarta Sans', sans-serif",
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: "#2C3E50" ,
                                
                            }}>{item.question}</span>} 
                        key={index.toString()} 
                        style={{
                            borderBottom: "none", // Remove divider between panels
                            
                          }}
                        >
                        <p style={{ 
                            fontFamily: "'Plus Jakarta Sans', sans-serif",
                            fontSize: "16px",
                            color: "#7D8587",
                            lineHeight: "1.6",
                            maxWidth: '1000px'
                             }}>
                            {item.answer}
                        </p>
                        </Panel>
                        </Collapse>
                        </div>
                ))}
          
            <Button
                type="primary"
                style={styles.loadMoreButton}
                onClick={loadMoreFaqs}
            >
                {visibleFaqCount < allFaqData.length ? "Load more" : "Hide FAQs"}
            </Button>
            </div>
        </Container>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    icon: {
        marginRight:  "10px",
        marginBottom:  "0",
        color: "#316DBA",
    },
    title: {
        fontSize: "38px",
        fontWeight: "bold",
        textAlign: "center",
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        marginBottom: "10px",
        marginTop:'0px',
        color: "#333333",
    },
    subtitle: {
        fontSize: "16px",
        textAlign: "center",

        fontFamily: "'Plus Jakarta Sans', sans-serif",
        marginBottom: "20px",
        color: "#5A5A5A",
    },
    loadMoreButton: {
        marginTop: "20px",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
    integrationHeader: {
        display: "flex",
        alignSelf: "center",
        justifyContent:  "flex-start",
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        flexDirection:  "row",
        textAlign:  "center",
        justifySelf:'center'
    },
    integrationText: {
        color: "#316DBA",
        fontSize: "24px",
        fontWeight: 'bolder',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        textAlign:  "center",
    },
};

export default SectionFAQ;
