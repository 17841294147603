import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Input, Button, Row, Col, message } from 'antd';
import { CSSProperties } from 'react';
import Container from "../../../../style/styledComponent/Container";
import { fadein } from '../../../../style/keyframes';
import servicesIcon from '../../../../asesst/new/icon/services.svg';
import { saveFormDataTalkToUs } from '../../../../firebase/firebase.action';

const { Title, Text } = Typography;

const TalkToUsSection = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: '',
    });
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const validateForm = useCallback(() => {
        const { name, email, phone, company, message } = formData;
        const isValid = name !== '' && email !== '' && phone !== '' && company !== '' && message !== '';
        setIsFormValid(isValid);
    }, [formData]);

    useEffect(() => {
        validateForm();
    }, [formData, validateForm]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, '');
        setFormData({ ...formData, phone: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (!isFormValid) return;

            await saveFormDataTalkToUs(formData);
            message.success('Success submit data, Our team will contact you');
            setFormData({
                name: '',
                email: '',
                phone: '',
                company: '',
                message: '',
            });
        } catch (error) {
            console.error('Failed to submit data', error);
            message.error('Failed to submit data. Please try again.');
        }
    };

    const containerStyles: CSSProperties = {
        display: 'flex',
        flexDirection: windowWidth <= 768 ? 'column' : 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        animation: `1s ease-in 1s 1 both ${fadein}`,
        backgroundColor: '#00152F',
        width: '100%',
        padding: "1rem 10rem", // Adjust padding for small vs large screens
        color: '#fff',
        // borderRadius: '10px',
    };

    const styles: { [key: string]: CSSProperties } = {
        integrationHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: windowWidth <= 768 ? 'center' : 'flex-start',
            flexDirection: windowWidth <= 768 ? 'column' : 'row',
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        icon: {
            marginRight: windowWidth <= 768 ? '' : '10px',
            marginBottom: windowWidth <= 768 ? '10px' : '0',
            color: '#316DBA',
        },
        title: {
            fontWeight: '800',
            fontSize: windowWidth <= 768 ? '24px' : '38px',
            color: '#fff',
            marginTop: '10px',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        description: {
            fontSize: '16px',
            color: '#fff',
            width: windowWidth <= 768 ? '100%' : '500px',
            lineHeight: '1.5    ',
            fontFamily: '"Jakarta Sans", sans-serif',
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        formContainer: {
            backgroundColor: '#142354',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
        formItem: {
            width: '100%',
            marginBottom: '20px',
        },
        input: {
            borderRadius: '8px',
            padding: '12px',
            color: 'white',
            backgroundColor: '#202B4E',
            border: '1px solid black',
            width: '100%',
        },
        textArea: {
            borderRadius: '8px',
            color: 'white',
            backgroundColor: '#202B4E',
            border: '1px solid black',
            padding: '10px',
            width: '100%',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
        },
        button: {
            width: '260px',
            height: '44px',
            backgroundColor: '#316DBA',
            color: '#fff',
            border: 'none',
            borderRadius: '8px',
            fontSize: '16px',
            cursor: 'pointer',
        },
        buttonDisabled: {
            backgroundColor: '#A9A9A9',
            cursor: 'not-allowed',
        },
    };

    const headerStyles: CSSProperties = {
        padding: '50px 0',
        textAlign: 'left',
        width: '100%',
    };

    return (
        <Container style={containerStyles}>
            <div style={headerStyles}>
                <Col xs={24} md={12}>
                    <div style={styles.integrationHeader}>
                        <img alt="example" src={servicesIcon} style={styles.icon} />
                        <Text style={styles.title}>Talk to us</Text>
                    </div>
                </Col>
                <Title style={styles.title}>
                    Need an in-depth discussion regarding your needs?
                </Title>
                <div style={{maxWidth:'550px'}}>
                <Text style={styles.description}>
                    We will help your business with appropriate geospatial-based solutions to make it more effective and efficient.
                </Text>
                </div>
                <form onSubmit={handleSubmit}>
                    <Row gutter={[20, 20]} style={{marginTop:'20px'}}>
                        <Col span={12}>
                            <div style={styles.formItem}>
                            <Text style={{ color: '#fff', fontFamily: '"Jakarta Sans", sans-serif', }}>Name</Text>
                                <Input
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Ex: John Doe"
                                    style={styles.input}
                                    className="white-placeholder"
                                    required
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={styles.formItem}>
                            <Text style={{ color: '#fff', fontFamily: '"Jakarta Sans", sans-serif', }}>Email</Text>
                                <Input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Ex: mail@yourmail.com"
                                    style={styles.input}
                                    className="white-placeholder"
                                    required
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col span={12}>
                            <div style={styles.formItem}>
                            <Text style={{ color: '#fff', fontFamily: '"Jakarta Sans", sans-serif', }}>Phone Number</Text>
                                <Input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handlePhoneChange}
                                    placeholder="Ex: 628121212"
                                    style={styles.input}
                                    className="white-placeholder"
                                    required
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={styles.formItem}>
                            <Text style={{ color: '#fff', fontFamily: '"Jakarta Sans", sans-serif', }}>Institution</Text>
                                <Input
                                    name="company"
                                    value={formData.company}
                                    onChange={handleChange}
                                    placeholder="Ex: Geo Inovasi Nusantara"
                                    style={styles.input}
                                    className="white-placeholder"
                                    required
                                />
                            </div>
                        </Col>
                    </Row>
                    <div style={styles.formItem}>
                    <Text style={{ color: '#fff', fontFamily: '"Jakarta Sans", sans-serif', }}>Message</Text>
                        <Input.TextArea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Your message"
                            rows={10}
                            className="white-placeholder"
                            style={styles.textArea}
                            required
                        />
                    </div>
                    <div style={styles.buttonContainer}>
                        <Button
                            htmlType="submit"
                            style={isFormValid ? styles.button : { ...styles.button, ...styles.buttonDisabled }}
                            disabled={!isFormValid}
                        >
                            Send
                        </Button>
                    </div>
                </form>
            </div>
        </Container>
    );
};

export default TalkToUsSection;
