import React, { useEffect, useState } from 'react';
import Container from "../../../../style/styledComponent/Container";
import { Card, Typography, Row, Col } from 'antd';
import easyIcon from '../../../../asesst/new/icon/icon.mini.svg';
import instanSection from "../../../../asesst/new/icon/action-1.svg";
import selecProd from "../../../../asesst/new/icon/action-2.svg";
import directAccess from "../../../../asesst/new/icon/action-3.svg";
import { fadein } from '../../../../style/keyframes';

const { Title, Text } = Typography;

function SectionInAction() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const services = [
        {
            img: instanSection,
            title: "Indonesia, Split into Squares",
            description: "We divide Indonesia into detailed 50x50 meter squares, enabling precise, location-specific data that transcends administrative boundaries, providing unparalleled granularity.",
        },
        {
            img: selecProd,
            title: "Rich Data in Every Square",
            description: "Each square delivers insights across customizable themes such as demographics, people traffic, reachability, and more, providing tailored, actionable intelligence for various industries.",
        },
        {
            img: directAccess,
            title: "Flexible and Scalable",
            description: "Our platform scales effortlessly, from analyzing a single square to an entire city, while offering the flexibility to integrate themes based on business requirements. GeoSquare adapts to your project size and goals.",
        },
    ];

    const containerStyles: React.CSSProperties = {
        position: "relative",
        width: "100%",
        height: "auto",
        padding: "1rem 10rem", // Adjust padding for small vs large screens
        backgroundSize: "cover",
        backgroundColor: "#F7F9FC",
        paddingBottom: "100px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };
    const integrationText: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: windowWidth <= 768 ? 'center' : 'flex-start',
        flexDirection: windowWidth <= 768 ? 'column' : 'row',
        textAlign: windowWidth <= 768 ? 'center' : 'left',
    };

    const headerStyles: React.CSSProperties = {
        padding: '50px 0',
        textAlign: 'left',
        width: '100%',
    };

    const cardContainerStyles: React.CSSProperties = {
        display: 'grid',
        gridTemplateColumns: services.length <= 3 ? 'repeat(auto-fit, minmax(300px, 1fr))' : 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '20px',
        width: '100%',
    };
    
    const cardStyles: React.CSSProperties = {
        maxHeight:"400px", 
        position: 'relative',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    };
    

    const cardHoverStyles: React.CSSProperties = {
        transform: 'translateY(-10px)', // Naik sedikit ke atas
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // Bayangan saat hover
    };

    const titleStyle: React.CSSProperties = {
        fontWeight: '800',
        fontSize: '38px',
        color: ' #333333',
        marginTop: '10px',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        textAlign: windowWidth <= 768 ? 'center' : 'left',
    }

    return (
        <>
            <Container data-testid="app" style={containerStyles}
                css={{
                    color: '#F7F9FC',
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                    animation: `1s ease-in 1s 1 both ${fadein}`,
                }}
            >
                <div style={headerStyles}>
                    <Row justify="start" align="middle">
                        <Col xs={24} md={22}>
                            <div style={integrationText}>
                                <img alt="example" src={easyIcon} style={{
                                    marginRight: windowWidth <= 768 ? '' : '10px',
                                    marginBottom: windowWidth <= 768 ? '10px' : '0',
                                    color: '#316DBA',
                                }} />
                                <p style={{
                                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                                    color: '#316DBA', fontSize: '24px', fontWeight: 'bolder'
                                }}>GeoSquare in Action</p>
                            </div>
                            <Title style={titleStyle}>Our Core Approach</Title>
                            <Text style={{
                                fontFamily: "'Plus Jakarta Sans', sans-serif",
                                fontSize: '16px', color: '#595959', display: 'block',
                                fontWeight: '400', lineHeight: '1.5', textAlign: windowWidth <= 768 ? 'center' : 'left'
                            }}>
                                Harness the power of precise, actionable geospatial insights tailored to your business needs. GeoSquare.ai offers unparalleled location intelligence by combining granular square-based data with rich, personalized themes, all designed for flexibility and scalability.
                            </Text>
                        </Col>
                    </Row>
                </div>
                <div style={cardContainerStyles}>
                    {services.map((service, index) => (
                        <Card 
                        key={index}
                        style={{
                            ...cardStyles,
                            ...(hoveredIndex === index ? cardHoverStyles : {}),
                        }}
                        bodyStyle={{
                            marginTop: '-20px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        }}
                        bordered={false}
                        cover={
                            <div 
                                style={{
                                    padding: '10px', // Tambahkan padding di sekitar gambar
                                    backgroundColor: '#fff', // Opsional: Warna latar jika diinginkan
                                    borderRadius: '10px', // Opsional: Radius untuk desain yang lembut
                                }}
                            >
                                <img 
                                    alt={service.title} 
                                    src={service.img} 
                                    style={{
                                        width: '100%', // Gambar memenuhi lebar kartu
                                        height: 'auto', // Sesuaikan tinggi gambar secara proporsional
                                        maxHeight: '300px', // Atur batas tinggi maksimum agar tetap proporsional
                                        objectFit: 'cover', // Pastikan gambar tetap proporsional tanpa ada ruang kosong
                                        borderRadius: '8px', // Efek lembut pada tepi gambar
                                    }}
                                />
                            </div>
                        }
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <Title style={{ fontFamily: "'Plus Jakarta Sans', sans-serif", fontSize: '24px', fontWeight: 700 }}>
                            {service.title}
                        </Title>
                        <Text style={{ fontFamily: "'Plus Jakarta Sans', sans-serif" }}>
                            {service.description}
                        </Text>
                    </Card>
                    
                    ))}
                </div>
            </Container>
        </>
    );
}

export default SectionInAction;
