// drawerSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeoJSONFeature } from '../../component/newmap/helper/map/geocode';
interface locationState {
    address: string;
    centroid: number[][];
    area: number
}

interface DataIds {
    product: string;
    subdata?: string[]; // Dynamic subdata, optional
}
interface AddChart {
    polygon_id: string;
    dataIds: DataIds[];
}
// ----- interface data card selected ----- //
interface ItemCardProps {
    id: string;
    polygonId: string;
    readiness: string;
    subData: any;
    version: any;
    title: string;
    productId: string;
    carPrice: string;
    description: string;
    price: string;
    size: any,
    totalpixel: any,
    status: string,
    output: any,
    year: number
    areaSize: any
    address: any,
    centeroid: {
        lattitude: number,
        longitude: number
    }
}

interface dataLayerGroup {
    columnName: string;
    productName: string;
    subdataName: string;
    additinal: any;
    combinedName: string;
    seriesName: string;
    isSeries: boolean;
    bins: number[];
    min: number;
    max: number;
}
// ---- end interface data card selected ---- //
interface CatalogState {
    catalog: any | null;
    isloading: boolean; // Add loading property
    error: boolean;
    paramsCatalogGeometry: any;
    location: locationState | null,
    drawActive: boolean,
    isModalCheckout: boolean,
    isModalCart: boolean,
    addToChart: AddChart[] | null; // Add dataIds to state
    selectedItem: ItemCardProps | null
    listCart: any
    setCartCheckout: any
    myData: any | null;
    detailMydata: any | null;
    metaData: any | null;
    dataLayerGroup: dataLayerGroup[];
    colorGroup: any | null;
    invoice_id: any | null;
    isModalConfirmPayment: boolean;
    selectedCategory: any | null;
    showCardCatalog: boolean;
    showCardMyData: boolean;
    showDrawerBottom: boolean;
    modalWalletPayment: boolean;
    popUpFilter: any[];
    // polygon drawer
    isDrawActive: boolean;
    drawnPolygon: GeoJSONFeature | null;
    isochrones: any | null;
    invoiceForData: any | null;
    downloadData: any | null;
    modalWalletCartPayment: boolean;

}

const initialState: CatalogState = {
    catalog: null,
    paramsCatalogGeometry: null,
    isloading: true, // Initialize loading state
    error: false,
    location: null,
    drawActive: false,
    isModalCheckout: false,
    addToChart: null,
    selectedItem: null,
    listCart: null,
    isModalCart: false,
    setCartCheckout: null,
    myData: null,
    detailMydata: null,
    metaData: null,
    dataLayerGroup: [],
    colorGroup: null,
    invoice_id: null,
    isModalConfirmPayment: false,
    selectedCategory: null,
    showCardCatalog: false,
    showCardMyData: false,
    showDrawerBottom: false,
    modalWalletPayment: false,
    popUpFilter: [],
    // polygon drawer
    isDrawActive: false,
    drawnPolygon: null,
    isochrones: null,
    invoiceForData: null,
    downloadData: null,
    modalWalletCartPayment: false

};

const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {

        setCatalogStart: (state) => {
            state.isloading = true; // Set loading to true when fetching starts
            state.error = false;
        },
        setCatalog: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.catalog = action.payload;
        },
        setCatalogGeometry: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.paramsCatalogGeometry = action.payload;
        },
        setCataloglocation: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.location = action.payload;
        },

        setDrawActive: (state, action) => {
            state.drawActive = action.payload;
        },
        setOpenModalCheckout(state) {
            state.isModalCheckout = true;
        },
        setCloseModalCheckout(state) {
            state.isModalCheckout = false;
        },
        setAddToChart: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.addToChart = action.payload;
        },
        setSelectedItem: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.selectedItem = action.payload;
        },
        setListCart: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.listCart = action.payload;
        },
        setOpenModalCart(state) {
            state.isModalCart = true;
        },
        setCloseModalCart(state) {
            state.isModalCart = false;
        },
        setCart: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.setCartCheckout = action.payload;
        },
        setMyData: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.myData = action.payload;
        },
        setDetailMyData: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.detailMydata = action.payload;
        },
        setMetaData: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.metaData = action.payload;
        },
        setDataLayerGroup: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.dataLayerGroup = action.payload;
        },
        setColorGroup: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.colorGroup = action.payload;
        },
        setInvoiceId: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.invoice_id = action.payload;
        },
        setOpenModalConfirmPayment(state) {
            state.isModalConfirmPayment = true;
        },
        setCloseModalConfirmPayment(state) {
            state.isModalConfirmPayment = false;
        },
        setSelectedCategory: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.selectedCategory = action.payload;
        },
        setShowCardCatalog: (state, action: PayloadAction<any>) => {
            state.showCardCatalog = action.payload;
        },
        setShowCardMyData: (state, action: PayloadAction<any>) => {
            state.showCardMyData = action.payload;
        },
        setShowDrawerBottom: (state, action: PayloadAction<any>) => {
            state.showDrawerBottom = action.payload;
        },
        setPopUpFilter: (state, action: PayloadAction<any>) => {
            state.isloading = false;
            state.popUpFilter = action.payload;
        },
        setModalWalletPayment: (state, action: PayloadAction<any>) => {
            state.modalWalletPayment = action.payload;
        },
        setIsDrawActive: (state, action: PayloadAction<any>) => {
            state.isDrawActive = action.payload;
        },
        setDrawnPolygon: (state, action: PayloadAction<any>) => {
            state.drawnPolygon = action.payload;
        },
        setIsochrones: (state, action: PayloadAction<any>) => {
            state.isochrones = action.payload;
        },
        setInvoiceForData: (state, action: PayloadAction<any>) => {
            state.invoiceForData = action.payload;
        },
        setDownloadData: (state, action: PayloadAction<any>) => {
            state.downloadData = action.payload;
        },
        setModalWalletCartPayment: (state, action: PayloadAction<any>) => {
            state.modalWalletCartPayment = action.payload;
        },
    },
});

export const {
    setCatalog,
    setCatalogGeometry,
    setCataloglocation,
    setCatalogStart,
    setDrawActive,
    setOpenModalCheckout,
    setCloseModalCheckout,
    setAddToChart,
    setSelectedItem,
    setListCart,
    setOpenModalCart,
    setCloseModalCart,
    setCart,
    setMyData,
    setDetailMyData,
    setMetaData,
    setDataLayerGroup,
    setColorGroup,
    setInvoiceId,
    setOpenModalConfirmPayment,
    setCloseModalConfirmPayment,
    setSelectedCategory,
    setShowCardCatalog,
    setShowCardMyData,
    setShowDrawerBottom,
    setPopUpFilter,
    setModalWalletPayment,
    setIsDrawActive,
    setDrawnPolygon,
    setIsochrones,
    setInvoiceForData,
    setDownloadData,
    setModalWalletCartPayment
} = catalogSlice.actions;

export default catalogSlice.reducer;
