import * as turf from '@turf/turf';
import maplibregl from 'maplibre-gl';
import icAreaSize from '../../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import { formatNumberWithDots } from '../formatNumber';

let currentPopup: maplibregl.Popup | null = null;

export const removePopupIsochrones = () => {
    if (currentPopup) {
        currentPopup.remove();
        currentPopup = null;
    }
};

export const addIsochroneLayer = async (map: maplibregl.Map, data: any) => {

    if (data) {
        const isochroneData = await data;

        if (map.getSource('isochrone-source')) {
            map.removeLayer('isochrone-layer');
            map.removeSource('isochrone-source');
        }

        map.addSource('isochrone-source', {
            type: 'geojson',
            data: isochroneData,
        });

        map.addLayer({
            id: 'isochrone-layer',
            type: 'fill',
            source: 'isochrone-source',
            layout: {},
            paint: {
                // Conditional color based on the 'type' property
                'fill-color': [
                    'case',
                    ['==', ['get', 'location_type'], 'outbound'], '#4ab046', // Green for 'outbound'
                    '#f56f42' // Yellow for other types
                ],
                'fill-opacity': 0.5,
            },
        });
        const bbox = turf.bbox(isochroneData);
        // console.log('isochronse',isochroneData?.features?.[0]?.geometry);
        
        const northwest = [bbox[0], bbox[3]]; // Coordinates of the northwestern corner of the bounding box
        const area = turf.area(isochroneData?.features?.[0]?.geometry); // Returns area in m²
        const areaInKm2 = area / 1_000_000; // Convert to km²
        // console.log('areaInKm2',areaInKm2);
        const formattedAreaInKm2 = areaInKm2.toFixed(0); // Format to 2 decimal places
// console.log('areaInKm2', Math.round(areaInKm2));

        if (currentPopup) {
            currentPopup.remove();
        }
        removePopupIsochrones(); // Remove existing popup if any
        currentPopup = new maplibregl.Popup({ className: 'reachability-popup', closeOnClick: false })
            .setLngLat(northwest as [number, number])
            .setHTML(`
                <div style="display: flex; align-items: center;">
                    <img src=${icAreaSize} alt="icon" style="width: 20px; height: 20px; margin-right: 5px;" />
                    <h3 style="color: white; margin: 0;">Reachability Area</h3>
                </div>
                <h3 style="color: white; margin: 5px 0; margin-left: 25px">${formatNumberWithDots(parseFloat(formattedAreaInKm2))} km²</h3>
                <div style="display: flex; flex-direction: column; gap: 5px; margin: 5px 0; margin-left: 25px">
                <div style="display: flex; align-items: center;">
                    <button style="width: 20px; height: 20px; background-color: #4ab046; border: none; border-radius: 3px; margin-right: 8px;"></button>
                    <h4 style="color: white;">Outbound</h4>
                </div>
                <div style="display: flex; align-items: center;">
                    <button style="width: 20px; height: 20px;background-color: #f56f42; border: none; border-radius: 3px; margin-right: 8px;"></button>
                    <h4 style="color: white;">Inbound</h4>
                </div>
            </div>
            `).addTo(map);

    }
};
