import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { notification, Modal } from 'antd';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';
import { formatDate } from '../../../component/newmap/helper/date';
import { LiaCoinsSolid } from "react-icons/lia";
import useReduxPayment from '../../../hoc/hooks/usePayment';
import { v4 as uuidv4 } from 'uuid';
import {
    auth,
} from '../../../firebase/firebaseConfig';

interface CheckoutInterface {
    uid: string;
    name: string;
    email: string;
    number: string;
    order_id: string;
    dataItems: GroupedData[];
    type: string;
    status_payment: string;
    status_data: string;
    output_status: string;
    created_at: string | any;
    deleted_at: string;
    updated_at: string;
    externalId: string;
    payerEmail: string;
    description: string;
    currency: string;
    invoiceDuration: number;
}

// transform data
interface Subdata {
    subdata: string;
    alias: string;
}

interface Additional {
    size: number | null;
}

interface Item {
    polygon_id: string;
    area: number;
    product: string;
    additional: Additional;
    created_at: string;
    subdata: Subdata[];
    total_grid: number;
    id: string;
    alias: string;
    currency: string;
    address: string;
    type: string;
    type_data: string;
    totalPrice: number;
    centroid: []

}

interface GroupedData {
    polygon_id: string;
    dataIds: Omit<Item, 'polygon_id' | 'total_grid' | 'area' | 'address' | 'centroid' | 'id' | 'currency' | 'created_at' | 'alias' | 'type' | 'type_data' | 'totalPrice'>[];
    total_grid: number;
    area: number;
    address: string;
    centroid: []
}

export const ModalWalletCartMethode: React.FC<any> = ({ isModalConfirmPayment }) => {
    const [, setLoading] = useState(false);  // Loading state for fetching user data
    const { setCartCheckout } = useReduxCatalog();
    // const { getDetailUser, dataUser } = useReduxUser();
    const { setCreatePaymentWallet } = useReduxPayment();
    const [paymentCheckout, setPaymentCheckout] = useState<CheckoutInterface | null>(null);
    const {
        setModalCloseCheckout,
        setSelectedItem,
        setModalWalletCartPayment,
    } = useReduxCatalog();

    const calculateTotalPrice = (items: any[]): number => {
        return items?.reduce((total, item) => total + item.totalPrice, 0);
    };
    const totalPrice = calculateTotalPrice(setCartCheckout);
    const user = auth.currentUser;

    function groupData(data: Item[]): GroupedData[] {
        const grouped: Record<string, GroupedData> = {};

        data?.forEach(item => {
            // Destructure to exclude unwanted fields
            const { polygon_id, total_grid, area, address, centroid, id, currency, created_at, alias, type, type_data, totalPrice, ...rest } = item;

            if (!grouped[polygon_id]) {
                grouped[polygon_id] = {
                    polygon_id,
                    total_grid,
                    area,
                    centroid,
                    address,
                    dataIds: []
                };
            }
            grouped[polygon_id].dataIds.push(rest);
        });
        return Object.values(grouped);
    }

    useEffect(() => {
        const uuid = uuidv4();
        const modifiedUuid = uuid.toUpperCase().replace('-', ''); // Hapus tanda '-' yang pertama
        const invUid = modifiedUuid.slice(0, 12)
        const now = new Date();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Get current month (add 1 because getMonth() returns 0-11)
        const year = now.getFullYear(); // Get current year
        const invoiceId = `INV-GE0-${invUid}-${month}-${year}`;
        const result: GroupedData[] = groupData(setCartCheckout);
        // console.log(result);
        
        setPaymentCheckout({
            uid: user?.uid || "",
            name: user?.displayName || "",
            email: user?.email || "",
            number: `${user?.phoneNumber || 0}`,
            order_id: invoiceId,
            dataItems: result, // Ensure it's an array
            type: "",
            status_payment: "waiting",
            status_data: "waiting",
            output_status: "available",
            created_at: now.toISOString(),
            deleted_at: "",
            updated_at: "",
            externalId: invoiceId,
            payerEmail: user?.email || "",
            description: "purchasing data",
            currency: "IDR",
            invoiceDuration: 86400, // Assuming this is correct
        });

    }, [setCartCheckout, user]);

    const handleSubmit = async () => {
        setLoading(true); // Set loading to true
        try {
            const response = await setCreatePaymentWallet(paymentCheckout);
            if (response && response.success) {
                handleCancel(); // Close the modal
                setModalCloseCheckout(false);
                setSelectedItem(null)
                notification.success({
                    style: {
                        backgroundColor: '#333939',
                        color: 'white'  // Menjadikan teks berwarna putih
                    },
                    message: <p style={{ color: 'white', fontWeight: 'bold' }}>Successful</p>,
                    description: <p style={{ color: 'white', fontWeight: 'bold' }}>Successfully Please check your DATA for further actions.</p>,
                    placement: 'topRight',
                });
            }
        } catch (error) {
            // Handle errors during payment checkout
            notification.error({
                style: {
                    backgroundColor: '#333939',
                    color: 'white',  // Make the text white
                },
                message: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed</p>,
                description: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed to checkout data. Please try again</p>,
                placement: 'topRight',
                closeIcon: <span style={{ color: 'white' }}>×</span>
            });
        } finally {
            setLoading(false); // Set loading to false after the operation completes
            handleCancel(); // Close the modal
            // setOpenModalConfirmPayment(true); // Open payment confirmation modal
        }
    };
    const handleCancel = () => {
        setModalWalletCartPayment(false);

    };
    // console.log(totalPrice);

    return (
        <Modal
            onCancel={handleCancel}
            styles={{
                content: {
                    backgroundColor: '#333939'
                },
                body: {
                    backgroundColor: '#333939',
                    color: 'white'
                },
            }}
            open={isModalConfirmPayment}
            width={400}
            footer={false}
            style={{ top: '3%' }}
            cancelText="Cancel"
            closeIcon={<CloseOutlined style={{ color: 'white' }} />} // Set the close icon color to white
        >
            <div
                style={{
                    backgroundColor: 'transparent',
                    width: '350px',
                    textAlign: 'center',
                    color: '#fff',
                }}
            >
                <div
                    style={{
                        width: '100px',
                        height: '100px',
                        margin: '0 auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // marginBottom: '20px',
                    }}
                >
                    <LiaCoinsSolid size={64} style={{ color: '#48BF40', fontSize: '20px' }} />
                </div>
                <h2 style={{ fontSize: '15px', margin: '0 0 18px' }}>You will use your GeoCredit to complete the purchase order.</h2>

                {/* <div style={{
                    border: '2px solid #fff',
                    borderRadius: '8px',
                    textAlign: 'center'
                }}>
                    <h2 style={{ fontSize: '24px' }}>Current Balance</h2>
                    {dataUser?.balance < amount && (
                    <p style={{ color: 'red'}}>
                       Balance not enough
                    </p>
                )}
                    <h1
                        style={{
                            fontSize: '32px',
                            margin: '0 0 20px',
                            color: dataUser?.balance < amount ? 'red' : 'white', // Change color to red if balance is less than amount
                        }}
                    >
                        Rp {formatNumberWithDots(dataUser?.balance)}
                    </h1>
                </div> */}
                <div
                    style={{
                        fontSize: '14px',
                        textAlign: 'left',
                        marginBottom: '20px',
                        marginTop: '20px',
                        color: '#ddd',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',

                    }}
                >
                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Account Email</strong>
                        <span style={{ textAlign: 'right' }}>{paymentCheckout?.email}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Date Purchase</strong>
                        <span style={{ textAlign: 'right' }}>{formatDate(paymentCheckout?.created_at)}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <strong style={{ textAlign: 'left' }}>Data</strong>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                            {setCartCheckout?.map((item: any, index: number) => (
                                <span key={index}>- {item?.alias}</span>
                            ))}
                        </div>
                    </div>

                    {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Description</strong>
                        <span style={{ textAlign: 'right' }}>{paymentCheckout?.description}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Date Purchase</strong>
                        <span style={{ textAlign: 'right' }}>{formatDate(paymentCheckout?.created_at)}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Account Email</strong>
                        <span style={{ textAlign: 'right' }}>{paymentCheckout?.email}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Total Amount</strong>
                        <span style={{ textAlign: 'right' }}> Rp {formatNumberWithDots(amount)}</span>
                    </div> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <strong style={{ textAlign: 'left' }}>Total Amount</strong>
                        <span style={{ textAlign: 'right' }}> Rp {formatNumberWithDots(totalPrice)}</span>
                    </div> 
                </div>

                <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'space-between',
                    }}
                >
                    <button
                        //  disabled={dataUser?.balance < amount} 
                        onClick={handleSubmit}
                        style={{
                            backgroundColor: '#4A7FC0', // Gray out if disabled
                            color: '#fff',
                            width: '100%',
                            fontWeight: 400,
                            padding: '10px 16px',
                            borderRadius: '8px',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        Buy
                    </button>
                </div>
            </div>
        </Modal>
    )
}