
import React, { useEffect, useState } from 'react';
import Container from "../../../../style/styledComponent/Container";
import { Card, Typography, Row, Col } from 'antd';
import easyIcon from '../../../../asesst/new/icon/hand.svg';
import instanSection from "../../../../asesst/new/img/instant.svg";
import selecProd from "../../../../asesst/new/img/effortles.svg";
import directAccess from "../../../../asesst/new/img/direct.svg"
import { fadein } from '../../../../style/keyframes';

const { Title, Text } = Typography;

function SectionEasyAccess() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const services = [
        {
            img: selecProd,
            title: "Effortless Exploration",
            description: "Explore our user-friendly and informative catalog to break through technical barriers and access the solutions you need with ease.",
        },
        {
            img: instanSection,
            title: "Instant Purchase",
            description: "Experience seamless and transparent transactions with our clear pricing and straightforward checkout process. Purchase exactly what you need without the hassle of complicated contracts or hidden fees.",
        },
        {
            img: directAccess,
            title: "Direct Access",
            description: "Easily integrate your chosen products with any GIS or BI platform, ensuring you can immediately start utilizing the data to drive your projects forward.",
        },
    ];

    const containerStyles: React.CSSProperties = {
        position: "relative",
        width: "100%",
        height: "auto",
        padding: "1rem 10rem", // Adjust padding for small vs large screens
        backgroundSize: "cover",
        backgroundColor: "#F7F9FC",
        paddingBottom: "100px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };
    const integrationText: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: windowWidth <= 768 ? 'center' : 'flex-start',
        flexDirection: windowWidth <= 768 ? 'column' : 'row',
        textAlign: windowWidth <= 768 ? 'center' : 'left',
    };

    const headerStyles: React.CSSProperties = {
        padding: '50px 0',
        textAlign: 'left',
        width: '100%',
    };

    const cardContainerStyles: React.CSSProperties = {
        display: 'grid',
        gridTemplateColumns: services.length <= 3 ? 'repeat(auto-fit, minmax(300px, 1fr))' : 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '20px',
        width: '100%',
    };

    const cardStyles: React.CSSProperties = {
        maxHeight: "650px",
        position: 'relative',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    };


    const cardHoverStyles: React.CSSProperties = {
        transform: 'translateY(-10px)', // Naik sedikit ke atas
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // Bayangan saat hover
    };

    const titleStyle: React.CSSProperties = {
        fontWeight: '800',
        fontSize: '38px',
        color: ' #333333',
        marginTop: '10px',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        textAlign: windowWidth <= 768 ? 'center' : 'left',
    }

    return (
        <>
            <Container data-testid="app" style={containerStyles}
                css={{
                    color: '#F7F9FC',
                    width: "100%",
                    height: "100%",
                    opacity: "0",
                    animation: `1s ease-in 1s 1 both ${fadein}`,
                }}
            >
                <div style={headerStyles}>
                    <Row justify="start" align="middle">
                        <Col xs={24} md={22}>
                            <div style={integrationText}>
                                <img alt="example" src={easyIcon} style={{
                                    marginRight: windowWidth <= 768 ? '' : '10px',
                                    marginBottom: windowWidth <= 768 ? '10px' : '0',
                                    color: '#316DBA',
                                }} />
                                <p style={{
                                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                                    color: '#316DBA', fontSize: '24px', fontWeight: 'bolder'
                                }}>Easy Access</p>
                            </div>
                            <Title style={titleStyle}>Accessing geospatial solutions has never been simpler!</Title>
                            <Text style={{
                                fontFamily: "'Plus Jakarta Sans', sans-serif",
                                fontSize: '16px', color: '#595959', display: 'block',
                                fontWeight: '400', lineHeight: '1.5', textAlign: windowWidth <= 768 ? 'center' : 'left'
                            }}>
                                We have simplified the discovery process into just three steps, making it easy for you to find the right solution.
                            </Text>
                        </Col>
                    </Row>
                </div>
                <div style={cardContainerStyles}>
                    {services.map((service, index) => (
                        <Card
                            key={index}
                            style={{
                                ...cardStyles,
                                ...(hoveredIndex === index ? cardHoverStyles : {}),
                            }}
                            bodyStyle={{
                                marginTop: '-20px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                            }}
                            bordered={false}
                            cover={
                                <div
                                    style={{
                                        paddingTop:'10px',
                                        // padding: '10px', // Tambahkan padding di sekitar gambar
                                        backgroundColor: '#fff', // Opsional: Warna latar jika diinginkan
                                        borderRadius: '10px', // Opsional: Radius untuk desain yang lembut
                                    }}
                                >
                                    <img
                                        alt={service.title}
                                        src={service.img}
                                        style={{
                                            width: '100%', // Gambar memenuhi lebar kartu
                                            height: 'auto', // Sesuaikan tinggi gambar secara proporsional
                                            maxHeight: '350px', // Atur batas tinggi maksimum agar tetap proporsional
                                            objectFit: 'cover', // Pastikan gambar tetap proporsional tanpa ada ruang kosong
                                            borderRadius: '8px', // Efek lembut pada tepi gambar
                                        }}
                                    />
                                </div>
                            }
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <Title style={{ fontFamily: "'Plus Jakarta Sans', sans-serif", fontSize: '24px', fontWeight: 700 }}>
                                {service.title}
                            </Title>
                            <Text style={{ fontFamily: "'Plus Jakarta Sans', sans-serif" }}>
                                {service.description}
                            </Text>
                        </Card>

                    ))}
                </div>
            </Container>
        </>
    );
}

export default SectionEasyAccess;
