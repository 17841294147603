import React, { useEffect, useState } from 'react';
import Container from "../../../../style/styledComponent/Container";
import { fadein } from "../../../../style/keyframes";
import partnerImage from "../../../../asesst/new/ourpartners-2.svg";
import { Typography } from 'antd';
import partnerIcon from '../../../../asesst/new/icon/easyaccess.svg';
import { CSSProperties } from 'react';

const { Title, Text } = Typography;
const SectionOurPartners = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const styles: { [key: string]: CSSProperties } = {
        integrationHeader: {
            display: "flex",
            alignItems: "center",
            justifyContent:  "flex-start",
            flexDirection:  "row",
            textAlign:  "left",
        },
        icon: {
            marginRight: windowWidth <= 768 ? "" : "10px",
            marginBottom: windowWidth <= 768 ? "10px" : "0",
            color: "#316DBA",
        },
        integrationText: {
            color: "#316DBA",
            fontSize: "24px",
            fontWeight: 'bolder',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            textAlign: windowWidth <= 768 ? "center" : "left",
        },
        title: {
            fontWeight: 'bolder',
            fontSize: windowWidth <= 768 ? "24px" : "38px",
            color: "#333333",
            marginTop: "10px",
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            textAlign: windowWidth <= 768 ? "center" : "left",
        },
        description: {
            fontSize: "16px",
            color: "#333333",
            display: "block",
            width: "90%",
            lineHeight: "1.5",
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            textAlign: "center"
        },
        logosGrid: {
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
        },
        logoItem: {
            filter: "grayscale(1)",
            maxWidth: "120px",
            height: "auto",
            margin: "auto",
        },
        imageStyle: {
            filter: 'grayscale(1)',
            width: '100%',
            height: 'auto',
        },
    };

    return (
        <Container
            css={{
                width: "100%",
                padding: '70px',
                backgroundColor: "#F7F9FC",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                animation: `1s ease-in 1s 1 both ${fadein}`,
               
            }}
        >
            <div style={styles.integrationHeader}>
                <img alt="Partner Icon" src={partnerIcon} style={styles.icon} />
                <Text style={styles.integrationText}>Our Partners</Text>
            </div>
            <Title style={styles.title}>Backed and trusted by many</Title>
            <Text style={styles.description}>
                We're proud to partner with leading universities, visionary venture capitalists, and innovative companies to accelerate progress. This collaboration fuels cutting-edge research, unlocks new possibilities, and brings groundbreaking solutions to market.
            </Text>

            {/* Partner Logos Section */}
            <div style={styles.logosGrid}>
                <img
                    src={partnerImage}
                    alt="Partner Logos"
                    style={styles.imageStyle}
                />
            </div>
        </Container>
    );
};

export default SectionOurPartners;