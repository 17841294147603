import React, { useEffect, useState } from 'react';
import Container from "../../../../style/styledComponent/Container";
import { fadein } from "../../../../style/keyframes";
// import partnerImage from "../../../../asesst/new/partner1.svg";
import apiImage from "../../../../asesst/new/img/new-api-img.svg";
import { Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import apiIcon from '../../../../asesst/new/icon/ic_outline-integration-instructions.svg';
import dashboardImg from '../../../../asesst/new/img/newdashboard.svg';
import { CSSProperties } from 'react';

const { Title, Text } = Typography;

const SectionApiCall = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClick = () => {
        navigate('/api-intergration');
    };
    const styles: { [key: string]: CSSProperties } = {
        integrationHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: windowWidth <= 768 ? 'center' : 'flex-start',
            flexDirection: windowWidth <= 768 ? 'column' : 'row',
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        icon: {
            marginRight: windowWidth <= 768 ? '' : '10px',
            marginBottom: windowWidth <= 768 ? '10px' : '0',
            color: '#FFFFFF',
        },
        integrationText: {
            color: '#FFFFFF',
            fontSize: '24px',
            fontWeight: '800',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        title: {
            fontWeight: '800',
            fontSize: windowWidth <= 768 ? '24px' : '38px',
            color: '#FFFFFF',
            marginTop: '10px',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        description: {
            fontSize: '16px',
            color: '#FFFFFF',
            fontWeight: '400',
            display: 'block',
            width: '440px',
            lineHeight: '1.5',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            textAlign: windowWidth <= 768 ? 'center' : 'left',
        },
        button: {
            width: '175px',
            height: '44px',
            marginTop: '20px',
            backgroundColor: '#316DBA',
            borderColor: '#316DBA',
            color: 'white',
            borderRadius:'8px',
            alignSelf: windowWidth <= 768 ? 'center' : 'flex-start',
        },
        imageStyle: {

            width: '100%',
            height: 'auto',
        },
    };

    return (
        <><Container
            css={{
                opacity: "0",
                animation: `1s ease-in 1s 1 both ${fadein}`,
                width: "100%",
                padding: "1rem 10rem", // Adjust padding for small vs large screens
                backgroundColor: '#00152F',
                display: "grid",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                cursor: "pointer",
                gridTemplateColumns: "1.5fr 2fr",
                '@media (max-width: 768px)': {
                    gridTemplateColumns: "1fr",
                    padding: "20px",
                }
            }}
        >
            <Container css={{ padding: '50px 0' }}>
                <Container
                    dir={"column"}
                    css={{
                        width: "100%",
                        height: "100%",
                        opacity: "0",
                        padding: '50px 0',
                        animation: `1s ease-in 1s 1 both ${fadein}`,
                        display: 'flex',
                        alignItems: windowWidth <= 768 ? 'center' : 'flex-start',
                    }}
                >
                    <div style={styles.integrationHeader}>
                        <img alt="example" src={apiIcon} style={styles.icon} />
                        <Text style={styles.integrationText}>Integration</Text>
                    </div>

                    <Title style={styles.title}>One API call away!</Title>
                    <Text style={styles.description}>
                        Effortlessly integrate our comprehensive geospatial insights, data, and services into you system with just a single API call!
                    </Text>
                    <Button onClick={handleClick} style={styles.button}>
                        <span style={{ color: 'white', fontSize: '14px', fontWeight: 'bolder', fontFamily: "'Plus Jakarta Sans', sans-serif", }}>Integrate with us!</span>
                    </Button>
                </Container>
            </Container>
            <Container css={{ padding: '50px 0' }}>
                <img
                    src={apiImage}
                    alt="Animated GIF"
                    className="responsive-image"
                    style={styles.imageStyle} />
            </Container>
        </Container><Container
            css={{
                margin: '0px',
                paddingLeft: windowWidth <= 768 ? '20px' : '55px',
                paddingRight: windowWidth <= 768 ? '20px' : '55px',
                opacity: "0",
                animation: `1s ease-in 1s 1 both ${fadein}`,
                width: "100%",
                backgroundColor: '#00152F',
            }}
        >
                <img src={dashboardImg} alt="Geospatial" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </Container></>
    );
};

export default SectionApiCall;