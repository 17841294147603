import maplibregl from 'maplibre-gl';

let currentMarker: maplibregl.Marker | null = null;
let currentMarkerId: string | null = null; // Track the marker's ID
let mapClickListener: ((e: maplibregl.MapMouseEvent) => void) | null = null; // Track the click listener

// Function to remove the current marker
export const removeCurrentMarker = () => {
    if (currentMarker) {
        currentMarker.remove();
        currentMarker = null;
        currentMarkerId = null; // Reset marker ID when the marker is removed
    }
};

export const enablePointCreation = (
    map: maplibregl.Map,
    isPointActive: boolean,
    markerId: string, // Add an identifier for the marker
    callback: (lngLat: maplibregl.LngLat) => void
) => {
    // Ensure existing listener is removed before adding a new one
    if (mapClickListener) {
        map.off('click', mapClickListener);
        mapClickListener = null;
    }

    if (isPointActive) {
        // Define the click listener
        map.getCanvas().style.cursor = 'crosshair';

        mapClickListener = async (e: maplibregl.MapMouseEvent) => {
            // Ensure the new marker is only added if its ID is not the same as the previous one
            if (currentMarkerId !== markerId) {
                removeCurrentMarker(); // Remove any existing marker

                // Place a new marker with the given ID
                currentMarker = new maplibregl.Marker({ draggable: true })
                    .setLngLat(e.lngLat)
                    .addTo(map);

                currentMarkerId = markerId; // Store the marker's ID

                callback(e.lngLat); // Invoke callback with coordinates

                // Update isochrone when marker is dragged
                currentMarker.on('dragend', async () => {
                    if (currentMarker) {
                        const newLngLat = currentMarker.getLngLat();
                        callback(newLngLat); // Callback with new coordinates
                    }
                });
            }
        };

        // Attach the listener
        map.on('click', mapClickListener);
    } else {
        map.getCanvas().style.cursor = '';
        // Clean up when point mode is deactivated
        removeCurrentMarker();
        if (map.getSource('isochrone-source')) {
            map.removeLayer('isochrone-layer');
            map.removeSource('isochrone-source');
        }
    }
};
