import React, { useState } from 'react';
import { Space } from 'antd';
import Text from '../../../style/styledComponent/Text';
import iconHeader from '../../../asesst/Icons/interactivemap/icon.svg';
import { MenuCatalog } from '../../../component/menu/menu.catalog';
import { MenuMyData } from '../../../component/menu/menu.mydata';
import { MenuChartCatalog } from '../../../component/menu/menu.cart.catalog';
import { MenuChatAi } from '../../../component/menu/menu.chat.ai';
import { useNavigate } from 'react-router-dom';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import { GeoJSONFeature, GeoJSONPoint, renderGeocodeResultToMap } from '../../../component/newmap/helper/map/geocode';
import { handleReverseGeocode } from '../../../component/newmap/helper/map/reversecode';
import { addIsochroneLayer } from '../../../component/newmap/helper/map/isochrones';

interface MenuItem {
    icon: string;
    label: string;
}

interface DrawerSideMenuProps {
    menuItems: MenuItem[];
    map: maplibregl.Map | null; // Pass the map instance as a prop
    draw: MapboxDraw
}

export const DrawerSideMenu: React.FC<DrawerSideMenuProps> = ({ menuItems, map, draw }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [clickedIndex, setClickedIndex] = useState<number | null>(null);
    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
    const [isMenuCatalogVisible, setIsMenuCatalogVisible] = useState<boolean>(false); // Track if MenuCatalog should be visible
    const navigate = useNavigate();

    const {
        setCatalogLocation,
        setIsochronest,
    } = useReduxCatalog();

    React.useEffect(() => {
        const fetchData = async () => {
            // Check localStorage for MenuCatalog visibility
            const localStorageData = localStorage.getItem('selectedLocation');
            if (localStorageData && localStorageData !== 'undefined' && localStorageData !== 'null') {
                setIsMenuCatalogVisible(true);
            } else {
                setIsMenuCatalogVisible(false);
            }

            // Fetch the geometry from localStorage
            const storedGeometry = localStorage.getItem('selectedLocation');

            if (storedGeometry && map) {
                const result = JSON.parse(storedGeometry); // Assuming stored geometry is a JSON string
                if (result && result.geometry) {
                    if (result.geometry.type === "Point") {
                        // Process point geometry
                        // console.log(result);
                        const geojson: GeoJSONFeature = {
                            type: 'Feature',
                            geometry: result.geometry as GeoJSONPoint,
                            properties: {},
                        };
                        const latlng = {
                            lat: geojson.geometry.coordinates[1],
                            lng: geojson.geometry.coordinates[0],
                        };

                        try {
                            const fetchedIsochrones = await setIsochronest(latlng);
                            if (fetchedIsochrones?.features) {
                                // Filter berdasarkan location_type == 'outbound'
                                const outboundFeatures = fetchedIsochrones.features.filter(
                                    (feature: any) => feature?.properties?.location_type === 'outbound'
                                );

                                const locationCode = await handleReverseGeocode(outboundFeatures?.[0]?.geometry);
                                setCatalogLocation(locationCode);

                                renderGeocodeResultToMap(map, geojson);
                                await addIsochroneLayer(map, fetchedIsochrones); // Render the isochrone laye
                            }

                        } catch (error) {
                            console.error('Error fetching address:', error);
                        }
                    }
                }
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [map, draw]); // eslint-disable-next-line

    const handleMouseEnter = (index: number) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };

    const handleClick = (index: number) => {
        setClickedIndex(index);
        setIsMenuVisible(true); // Set menu to visible when clicked
        localStorage.removeItem('selectedLocation'); // Replace 'yourKeyHere' with the actual key
        setIsMenuCatalogVisible(false); // Hide MenuCatalog if the user closes it
    };

    const handleCloseMenu = () => {
        setIsMenuVisible(false); // Hide the menu content
        setClickedIndex(null); // Reset the clicked index
        // Clear localStorage data when MenuCatalog is closed
        localStorage.removeItem('selectedLocation'); // Replace 'yourKeyHere' with the actual key
        setIsMenuCatalogVisible(false); // Hide MenuCatalog if the user closes it
    };

    const handleToLanding = () => {
        navigate('/');
    };

    const renderContent = () => {
        if (!isMenuVisible) return null;
        switch (clickedIndex) {
            case 0:
                return <MenuCatalog map={map} onClose={handleCloseMenu} draw={draw} />;
            case 1:
                return <MenuMyData onClose={handleCloseMenu} />;
            case 2:
                return <MenuChatAi onClose={handleCloseMenu} />;
            case 3:
                return <MenuChartCatalog onClose={handleCloseMenu} />;
            default:
                return null; // You can render a default component or return null if no match
        }
    };

    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    left: '20px',
                    top: '3%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <div
                    style={{
                        background: '#333939',
                        border: '1px #d9d9d9',
                        color: 'white',
                        borderRadius: '12px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                        width: '90px',
                        height: 'calc(100vh - 50px)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'start',
                    }}
                >
                    <div
                        onClick={handleToLanding}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '16px',
                            width: '100%',
                            transition: 'transform 0.3s ease',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            alt="icon"
                            style={{
                                padding: '10px',
                                borderRadius: '8px',
                                transition: 'border 0.3s ease',
                            }}
                            src={iconHeader}
                        />
                    </div>
                    {menuItems.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '16px',
                                width: '100%',
                                transition: 'transform 0.3s ease',
                                transform: activeIndex === index ? 'translateY(-5px)' : 'translateY(0)',
                                cursor: 'pointer',
                            }}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => handleClick(index)}
                        >
                            <img
                                alt="icon"
                                style={{
                                    backgroundColor: clickedIndex === index ? '#141617' : '#333939',
                                    padding: '10px',
                                    border: activeIndex === index || clickedIndex === index ? '1px solid #141617' : '1px solid transparent',
                                    borderRadius: '8px',
                                    transition: 'border 0.3s ease',
                                }}
                                src={item.icon}
                            />
                            {item.label && (
                                <Text css={{ fontSize: 'smaller', color: 'White', paddingTop: '5px' }}>{item.label}</Text>
                            )}
                        </div>
                    ))}
                </div>
            </Space>

            <div>
                {renderContent()}
                {isMenuCatalogVisible && <MenuCatalog map={map} onClose={handleCloseMenu} draw={draw} />}
            </div>
        </React.Fragment>
    );
};
