import * as turf from '@turf/turf';
import maplibregl from 'maplibre-gl';
import icAreaSize from '../../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import { formatNumberWithDots } from '../formatNumber';

export interface GeoJSONPolygon {
    type: 'Polygon';
    coordinates: number[][][];
}

export interface GeoJSONPoint {
    type: 'Point';
    coordinates: [number, number];
}

export interface GeoJSONFeature {
    type: 'Feature';
    geometry: GeoJSONPolygon | GeoJSONPoint;
    properties: Record<string, any>;
}

let currentPopup: maplibregl.Popup | null = null;
let currentMarker: maplibregl.Marker | null = null;
let currentPolygonSourceId: string | null = null;
let currentPolygonLayerId: string | null = null;

const removePopup = () => {
    if (currentPopup) {
        currentPopup.remove();
        currentPopup = null;
    }
};

const removeMarker = () => {
    if (currentMarker) {
        currentMarker.remove();
        currentMarker = null;
    }
};

const removePolygonLayer = (map: maplibregl.Map | null) => {
    if (map && currentPolygonSourceId) {
        // Remove any layers that are using the source first
        if (currentPolygonLayerId && map.getLayer(currentPolygonLayerId)) {
            map.removeLayer(currentPolygonLayerId);
        }

        if (currentPolygonLayerId && map.getLayer(`${currentPolygonLayerId}`)) {
            map.removeLayer(`${currentPolygonLayerId}`);
        }

        // Now remove the source
        if (map.getSource(currentPolygonSourceId)) {
            map.removeSource(currentPolygonSourceId);
        }
    }
};


export const clearGeocodeLayer = (map: maplibregl.Map | null) => {
    if (map) {
        // Remove previous layer and source if they exist
        removePolygonLayer(map);
        removePopup();
        removeMarker();
    }
};

export const renderGeocodeResultToMap = (
    map: maplibregl.Map | null,
    geojson: GeoJSONFeature,
    sourceId: string = 'geocode-result',
    layerId: string = 'geocode-layer'
): void => {
    if (!map) return;

    // Remove previous layer and source if they exist
    clearGeocodeLayer(map);

    // Check the geometry type and handle accordingly
    if (geojson.geometry.type === 'Polygon') {
        // Add new source for polygon
        currentPolygonSourceId = sourceId;
        currentPolygonLayerId = layerId;

        map?.addSource(sourceId, {
            type: 'geojson',
            data: geojson,
        });

        // Add the fill layer for the polygon
        map?.addLayer({
            id: layerId, // Unique ID for the fill layer
            type: 'fill',
            source: sourceId,
            layout: {},
            paint: {
                'fill-color': '#4A7FC0', // Fill color for the polygon
                'fill-opacity': 0.2,     // Adjust the opacity as needed
            },
        });

        // Add the border layer for the polygon
        map?.addLayer({
            id: `${layerId}`, // Unique ID for the border layer
            type: 'line',
            source: sourceId,
            layout: {},
            paint: {
                'line-color': '#4A7FC0',
                'line-width': 2,
            },
        });

        // Calculate the bounding box and area
        const bbox = turf.bbox(geojson);
        const northwest = [bbox[0], bbox[3]]; // Coordinates of the northwestern corner of the bounding box
        const area = turf.area(geojson); // Returns area in m²
        const areaInKm2 = area / 1_000_000; // Convert to km²
        const formattedAreaInKm2 = areaInKm2.toFixed(0); // Format to 0 decimal places

        // Remove the previous popup if it exists
        removePopup(); // Remove existing popup if any
        currentPopup = new maplibregl.Popup({ className: 'radius-popup', closeOnClick: false })
            .setLngLat(northwest as [number, number])
            .setHTML(`
            <div style="display: flex; align-items: center;">
                <img src=${icAreaSize} alt="icon" style="width: 20px; height: 20px; margin-right: 5px;" />
                <h3 style="color: white; margin: 0;">Size Area</h3>
            </div>
            <h3 style="color: white; margin: 5px 0; margin-left: 10px">${formatNumberWithDots(parseFloat(formattedAreaInKm2))} km²</h3>
        `)
            .addTo(map);

        // Convert the bounding box to a LngLatBounds object
        const bounds = new maplibregl.LngLatBounds(
            [bbox[0], bbox[1]],
            [bbox[2], bbox[3]]
        );

        map?.fitBounds(bounds, {
            padding: 20,
            maxZoom: 15,
            duration: 1000
        });
    } else if (geojson.geometry.type === 'Point') {
        // Handle rendering for Point type
        removePolygonLayer(map); // Remove previous polygon layers if present
        clearGeocodeLayer(map)
        const [longitude, latitude] = geojson.geometry.coordinates;

        currentMarker = new maplibregl.Marker()
            .setLngLat([longitude, latitude])
            .addTo(map);

        // Remove the previous popup if it exists
        map?.flyTo({
            center: [longitude, latitude],
            zoom: 13, // You can adjust this zoom level based on the desired level of detail
            duration: 1000 // Smooth zoom transition
        });
    }
};
