import React, { useState } from "react";
import { Typography, Row, Col,Spin } from 'antd';
import { useInView } from "react-intersection-observer";
import agnostic from "../../../../asesst/new/img/agnon.svg";
import expert from "../../../../asesst/new/img/expertBE.svg";
import hight from "../../../../asesst/new/img/highquality.svg";
import Container from "../../../../style/styledComponent/Container";
import suistanable from "../../../../asesst/new/img/suistanable.svg";
import easyIcon from '../../../../asesst/new/icon/howItWorck-ic.svg';
const { Title, Text } = Typography;


const SectionHowItWork: React.FC = () => {
    const slides = [
        {
            title: "Sustainable and Trusted Data Sources",
            description:
                "We filter and integrate accurate and sustainable data from government records, satellite imagery, cellular providers, public datasets, etc creating a reliable foundation for insights.",
            image: suistanable,
        },
        {
            title: "Expert-Backed and AI-Enhanced Processing",
            description:
                "We utilize layered spatial distributions and combines expert-based methods with AI-driven predictions to achieve high-resolution, 50x50 meter square insights.",
            image: expert,
        },
        {
            title: "High-Quality Insights through Partnerships",
            description:
                "We collaborate with SMEs to deliver thematic insights across demographics, traffic, reachability, and more, enhancing each square’s depth.",
            image: hight,
        },
        {
            title: "Platform-Agnostic and Accessible",
            description:
                "Seamlessly integrates with all GIS and BI platforms. For users without these tools, we offer a simple, intuitive interface to visualize the data.",
            image: agnostic,
        },
    ];
    const headerStyles: React.CSSProperties = {
        padding: '20px 0',
        textAlign: 'left',
        width: '100%',
    };
    const integrationText: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        textAlign: 'left',
    };
    const titleStyle: React.CSSProperties = {
        fontWeight: '800',
        fontSize: '38px',
        color: ' #333333',
        marginTop: '10px',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        textAlign: 'left',
    }


    const [activeIndex, setActiveIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [imageClass, setImageClass] = useState("");

    React.useEffect(() => {
        // Reset loading state and set fade-in class
        setIsLoading(true);
        setImageClass("fade-in");
    
        const timeout = setTimeout(() => {
            setImageClass(""); // Hapus kelas setelah animasi selesai
        }, 600); // Waktu ini harus sesuai dengan durasi animasi fade-in
    
        return () => clearTimeout(timeout);
    }, [activeIndex]);
    

    return (
        <Container
            dir={"column"}
            css={{
                position: "relative",
                width: "100%",
                height: "auto",
                padding: "1rem 10rem", // Adjust padding for small vs large screens
                backgroundSize: "cover",
                backgroundColor: "#fff",
                paddingBottom: "100px",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

            }}
        >
            <div style={headerStyles}>
                <Row justify="start" align="middle">
                    <Col xs={24} md={22}>
                        <div style={integrationText}>
                            <img alt="example" src={easyIcon} style={{
                                marginRight: '10px',
                                marginBottom: '0',
                                color: '#316DBA',
                            }} />
                            <p style={{
                                fontFamily: "'Plus Jakarta Sans', sans-serif",
                                color: '#316DBA', fontSize: '24px', fontWeight: 'bolder'
                            }}>Our Process</p>
                        </div>
                        <Title style={titleStyle}>How it Works</Title>
                        <Text style={{
                            fontFamily: "'Plus Jakarta Sans', sans-serif",
                            fontSize: '16px', color: '#595959', display: 'block',
                            fontWeight: '400', lineHeight: '1.2', textAlign: 'left'
                        }}>
                            We have simplified the discovery process into just three steps, making it easy for you to find the right solution.
                        </Text>
                    </Col>
                </Row>
            </div>
            <div
                style={{
                    display: "flex",
                    backgroundColor: "#fff",
                    width: "100%",
                    height: "80vh",
                    overflow: "hidden",
                }}
            >
                {/* Left Panel */}
                <div
                    style={{
                        flex: 1.2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
             
                        // padding: "20px",
                    }}
                >
                    {isLoading && (
                        <div
                            style={{
                                position: "absolute",
                                zIndex: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(255, 255, 255, 0.8)",
                            }}
                        >
                            <Spin size="large" />
                        </div>
                    )}
                    <img
                    key={activeIndex}
                        src={slides[activeIndex].image}
                        alt={slides[activeIndex].title}
                        className={imageClass}
                        style={{
                           
                            width: "100%",
                            height: "auto",
                            transition: "transform 0.6s ease-in-out",
                        }}
                        onLoad={() => setIsLoading(false)}
                    />
                </div>

                {/* Right Panel */}
                <div
                    style={{
                        flex: 1,
                        backgroundColor: "#fff",
                        overflowY: "scroll",
                        height: "70vh",
                        padding: "20px",
                        boxSizing: "border-box",
                        scrollSnapType: "y mandatory", // Tambahkan scroll snapping
                    }}
                >
                    {slides.map((slide, index) => (
                        <SlideSection
                            key={index}
                            index={index}
                            slide={slide}
                            isActive={activeIndex === index}
                            onVisible={() => setActiveIndex(index)}
                        />
                    ))}
                </div>
            </div>
        </Container>
    );
};

interface SlideSectionProps {
    index: number;
    slide: {
        title: string;
        description: string;
        image: string;
    };
    isActive: boolean;
    onVisible: () => void;
}

const SlideSection: React.FC<SlideSectionProps> = ({
    slide,
    onVisible,
}) => {
    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: false,
    });

    React.useEffect(() => {
        if (inView) {
            onVisible();
        }
    }, [inView, onVisible]);
    

    return (
        <div style={{
            backgroundColor: "#fff",
            width: "100%",
            height: "auto",
        }}>
            <div
                ref={ref}
                style={{
                    minHeight: "80vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start", // Mengatur elemen anak untuk disusun di kiri
                    padding: "20px",
                    marginBottom: "20px", // Jarak antar section
                    background: "#fff",
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                    borderRadius: "8px",
                    transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
                    scrollSnapAlign: "center", // Setiap card di center
                    width: "100%", // Mengambil lebar penuh
                }}
            >
                <h2 style={{ marginBottom: "10px", color: "#333", textAlign: "left", width: "60%", fontWeight: "24px" }}>
                    {slide.title}
                </h2>
                <p style={{ color: "#555", lineHeight: "1.6", fontWeight: '16px', width: "70%" }}>{slide.description}</p>
            </div>

        </div>
    );
};

export default SectionHowItWork;
