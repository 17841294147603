import React, { useEffect, useState } from "react";
// import IcoMenu from "../../../../../asesst/Icons/Ico_menu";
import { fadeinTop } from "../../../../style/keyframes";
import Flex from "../../../../style/styledComponent/Container";
import logogeosquare from "../../../../asesst/Icons/product/Geosquare-logo.png";
import { useNavigate } from "react-router-dom";
import { VscGraph } from "react-icons/vsc";
import { AiFillDatabase, AiOutlineSelect, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { MdOutlineMiscellaneousServices, MdOutlineRealEstateAgent, MdOutlineHealthAndSafety, MdOutlineSpaceDashboard } from "react-icons/md";
import { LuMonitorSmartphone, LuMonitorX, LuSmartphoneNfc } from "react-icons/lu";
import { FaArrowRightLong, FaArrowsDownToPeople } from "react-icons/fa6";
import { TbHeartRateMonitor, TbSmartHome, TbDatabaseDollar, TbMapSearch, TbSeeding } from "react-icons/tb";
import { FiTruck } from "react-icons/fi";
import { RiEarthquakeLine } from "react-icons/ri";
import { PiShareNetworkLight, PiChartPieSliceLight } from "react-icons/pi";
import { IoLogOutOutline, IoMenu, IoCloseCircleOutline, IoLayersOutline, IoRocketOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { PiLinkSimpleBold } from "react-icons/pi";
import { FaUsers } from "react-icons/fa";

import {
    signOut
} from "firebase/auth";

import {
    Popover,
    Menu,
    Avatar,
    message,
    Drawer,
    Button
} from 'antd';

import Text from "../../../../style/styledComponent/Text";
import useReduxUser from "../../../../hoc/hooks/useUser";
import { auth } from "../../../../firebase/firebaseConfig";
const { SubMenu } = Menu;
interface NavProps {
    style?: React.CSSProperties;
}

export const NavBar: React.FC<NavProps> = ({ style }) => {
    const { user, clearUser } = useReduxUser();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredSolution, setIsHoveredSolution] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    };


    const avatarUrl = user?.photoURL;
    const navigate = useNavigate();

    const handleClickSolutionData = () => {
        // Perform some logic, and then navigate
        navigate('/data');
    };
    const handleClicklogin = () => {
        // Perform some logic, and then navigate
        navigate('/login');
    };
    const handleClickSolutionInsight = () => {
        // Perform some logic, and then navigate
        navigate('/insight');
    };
    const handleClickSolutionSurvey = () => {
        // Perform some logic, and then navigate
        navigate('/survey');
    };

    const handleClickSolutionCurated = () => {
        // Perform some logic, and then navigate
        navigate('/others');
    };
    const handleClickHome = () => {
        // Perform some logic, and then navigate
        navigate('/');
    };
    const handleToDashboard = () => {
        // Perform some logic, and then navigate
        navigate('/dashboard');
    };


    const handleClickApiDashboard = () => {
        // Perform some logic, and then navigate
        navigate('/api-intergration');
    };


    const handleLogout = async () => {
        try {
            await signOut(auth);
            clearUser()
            message.success('Logout successful');
            navigate('/'); // Redirect to login page
        } catch (error) {
            console.error('Error logging out:', error);
            message.error('Failed to logout');
        }
    };
    const menu = (

        <Menu style={{
            width: "490px"
        }}>
            <Flex css={{
                marginTop: "20px"
            }}>
                <Menu.Item
                    onClick={handleClickSolutionInsight}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="1"
                    icon={
                        <div style={{
                            border: "1px solid #3983e0", // 
                            borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                            //  backgroundColor: "gray", // Mengatur background color menjadi gray
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            backgroundColor: "#3983e0", // Mengatur background color menjadi 
                        }}>
                            <VscGraph size={20} color="white" />
                        </div>
                    }
                >
                    GeoSquare Insights
                </Menu.Item>
                <Menu.Item
                    onClick={handleClickSolutionData}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="1"
                    icon={
                        <div style={{
                            border: "1px solid #3983e0", // Menambahkan border dengan solid gray
                            borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                            //  backgroundColor: "gray", // Mengatur background color menjadi gray
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            backgroundColor: "#3983e0", // Mengatur background color menjadi 
                        }}>
                            <AiFillDatabase size={20} color="white" />
                        </div>
                    }
                >
                    Analysis-Ready Dataset
                </Menu.Item>
            </Flex>
            <Flex>
                <Menu.Item
                    onClick={handleClickSolutionSurvey}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="3"
                    icon={
                        <div style={{
                            border: "1px solid #3983e0", // Menambahkan border dengan solid gray
                            borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                            //  backgroundColor: "gray", // Mengatur background color menjadi gray
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            backgroundColor: "#3983e0", // Mengatur background color menjadi 
                        }}>
                            <MdOutlineMiscellaneousServices size={20} color="white" />
                        </div>
                    }
                >
                    On-Demand Services
                </Menu.Item>
                <Menu.Item
                    onClick={handleClickSolutionCurated}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="4"
                    icon={
                        <>
                            <div style={{
                                border: "1px solid white", // Menambahkan border dengan solid gray
                                borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                display: "flex", // Flexbox untuk mengatur konten
                                justifyContent: "center", // Horizontal alignment ke tengah
                                alignItems: "center", // Vertical alignment ke tengah
                                width: "35px", // Lebar div
                                height: "35px", // Tinggi div
                                marginRight: "0.5rem",
                                backgroundColor: "#3983e0", // Mengatur background color menjadi 
                            }}>
                                <LuMonitorSmartphone size={20} color="white" />
                            </div>
                        </>
                    }>
                    Curated Platforms
                </Menu.Item>
            </Flex>
            <Menu.Item
                style={{
                    backgroundColor: "white",
                    color: "#3983e0",
                    display: 'flex',
                    alignItems: 'center',
                }}
                key="4"
                icon={
                    <>
                        <div style={{

                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            marginRight: "0rem"

                        }}>
                            <FaArrowRightLong size={20} color="#3983e0" />
                        </div>
                    </>
                }>
                All catalog
            </Menu.Item>
        </Menu>

    );

    const menuUseCase = (

        <Menu style={{
            width: "600px"
        }}>
            <Flex css={{
                marginTop: "20px"
            }}>
                <Menu.Item
                    // onClick={handleClickSolutionInsight}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="1"
                    icon={
                        <div style={{
                            border: "1px solid white", // Menambahkan border dengan solid gray
                            borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                            //  backgroundColor: "gray", // Mengatur background color menjadi gray
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            // marginRight:"0.5rem"
                            backgroundColor: "#3983e0",
                        }}>
                            <AiOutlineSelect size={20} color="white" />
                        </div>
                    }
                >
                    Site Selection
                </Menu.Item>
                <Menu.Item
                    // onClick={handleClickSolutionData}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="1"
                    icon={
                        <div style={{
                            border: "1px solid white", // Menambahkan border dengan solid gray
                            borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                            //  backgroundColor: "gray", // Mengatur background color menjadi gray
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            backgroundColor: "#3983e0",
                            // marginRight:"0.5rem"
                        }}>
                            <TbHeartRateMonitor size={20} color="white" />
                        </div>
                    }
                >
                    Geomarketing
                </Menu.Item>
            </Flex>
            <Flex>
                <Menu.Item
                    // onClick={handleClickSolutionSurvey}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="3"
                    icon={
                        <div style={{
                            border: "1px solid white", // Menambahkan border dengan solid gray
                            borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                            //  backgroundColor: "gray", // Mengatur background color menjadi gray
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            // marginRight:"0.5rem"
                            backgroundColor: "#3983e0",
                        }}>
                            <FiTruck size={20} color="white" />
                        </div>
                    }
                >
                    Supply Chain Optimization
                </Menu.Item>
                <Menu.Item
                    // onClick={handleClickSolutionCurated}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="4"
                    icon={
                        <>
                            <div style={{
                                border: "1px solid white", // Menambahkan border dengan solid gray
                                borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                display: "flex", // Flexbox untuk mengatur konten
                                justifyContent: "center", // Horizontal alignment ke tengah
                                alignItems: "center", // Vertical alignment ke tengah
                                width: "35px", // Lebar div
                                height: "35px", // Tinggi div
                                marginRight: "0.5rem",
                                backgroundColor: "#3983e0",
                            }}>
                                <TbSmartHome size={20} color="white" />
                            </div>
                        </>
                    }>
                    Out of Home Analytics
                </Menu.Item>
            </Flex>
            <Flex>
                <Menu.Item
                    // onClick={handleClickSolutionSurvey}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="3"
                    icon={
                        <div style={{
                            border: "1px solid white", // Menambahkan border dengan solid gray
                            borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                            //  backgroundColor: "gray", // Mengatur background color menjadi gray
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            // marginRight:"0.5rem"
                            backgroundColor: "#3983e0",
                        }}>
                            <RiEarthquakeLine size={20} color="white" />
                        </div>
                    }
                >
                    Catastrophe Modeling
                </Menu.Item>
                <Menu.Item
                    // onClick={handleClickSolutionCurated}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="4"
                    icon={
                        <>
                            <div style={{
                                border: "1px solid white", // Menambahkan border dengan solid gray
                                borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                display: "flex", // Flexbox untuk mengatur konten
                                justifyContent: "center", // Horizontal alignment ke tengah
                                alignItems: "center", // Vertical alignment ke tengah
                                width: "35px", // Lebar div
                                height: "35px", // Tinggi div
                                marginRight: "0.5rem",
                                backgroundColor: "#3983e0",
                            }}>
                                <TbDatabaseDollar size={20} color="white" />
                            </div>
                        </>
                    }>
                    Data Monetization
                </Menu.Item>
            </Flex>
            <Flex>
                <Menu.Item
                    // onClick={handleClickSolutionSurvey}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="3"
                    icon={
                        <div style={{
                            border: "1px solid white", // Menambahkan border dengan solid gray
                            borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                            //  backgroundColor: "gray", // Mengatur background color menjadi gray
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            backgroundColor: "#3983e0",
                            // marginRight:"0.5rem"
                        }}>
                            <LuMonitorX size={20} color="white" />
                        </div>
                    }
                >
                    Fraud Detection
                </Menu.Item>
                <Menu.Item
                    // onClick={handleClickSolutionCurated}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="4"
                    icon={
                        <>
                            <div style={{
                                border: "1px solid white", // Menambahkan border dengan solid gray
                                borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                display: "flex", // Flexbox untuk mengatur konten
                                justifyContent: "center", // Horizontal alignment ke tengah
                                alignItems: "center", // Vertical alignment ke tengah
                                width: "35px", // Lebar div
                                height: "35px", // Tinggi div
                                marginRight: "0.5rem",
                                backgroundColor: "#3983e0",
                            }}>
                                <MdOutlineRealEstateAgent size={20} color="white" />
                            </div>
                        </>
                    }>
                    Real Estate Market Analysis
                </Menu.Item>
            </Flex>
            <Flex>
                <Menu.Item
                    // onClick={handleClickSolutionSurvey}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="3"
                    icon={
                        <div style={{
                            border: "1px solid white", // Menambahkan border dengan solid gray
                            borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                            //  backgroundColor: "gray", // Mengatur background color menjadi gray
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            backgroundColor: "#3983e0",
                            // marginRight:"0.5rem"
                        }}>
                            <MdOutlineHealthAndSafety size={20} color="white" />
                        </div>
                    }
                >
                    Healthcare Analytics
                </Menu.Item>
                <Menu.Item
                    // onClick={handleClickSolutionCurated}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="4"
                    icon={
                        <>
                            <div style={{
                                border: "1px solid white", // Menambahkan border dengan solid gray
                                borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                display: "flex", // Flexbox untuk mengatur konten
                                justifyContent: "center", // Horizontal alignment ke tengah
                                alignItems: "center", // Vertical alignment ke tengah
                                width: "35px", // Lebar div
                                height: "35px", // Tinggi div
                                marginRight: "0.5rem",
                                backgroundColor: "#3983e0",
                            }}>
                                <LuSmartphoneNfc size={20} color="white" />
                            </div>
                        </>
                    }>
                    IoT Analytics and Smart Cities
                </Menu.Item>
            </Flex>
            <Flex>
                <Menu.Item
                    // onClick={handleClickSolutionSurvey}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="3"
                    icon={
                        <div style={{
                            border: "1px solid white", // Menambahkan border dengan solid gray
                            borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                            //  backgroundColor: "gray", // Mengatur background color menjadi gray
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            backgroundColor: "#3983e0",
                            // marginRight:"0.5rem"
                        }}>
                            <PiShareNetworkLight size={20} color="white" />
                        </div>
                    }
                >
                    Network Deployment
                </Menu.Item>
                <Menu.Item
                    // onClick={handleClickSolutionCurated}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="4"
                    icon={
                        <>
                            <div style={{
                                border: "1px solid white", // Menambahkan border dengan solid gray
                                borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                display: "flex", // Flexbox untuk mengatur konten
                                justifyContent: "center", // Horizontal alignment ke tengah
                                alignItems: "center", // Vertical alignment ke tengah
                                width: "35px", // Lebar div
                                height: "35px", // Tinggi div
                                marginRight: "0.5rem",
                                backgroundColor: "#3983e0",
                            }}>
                                <FaArrowsDownToPeople size={20} color="white" />
                            </div>
                        </>
                    }>
                    Mobility Planning
                </Menu.Item>
            </Flex>
            <Flex>
                <Menu.Item
                    // onClick={handleClickSolutionSurvey}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="3"
                    icon={
                        <div style={{
                            border: "1px solid white", // Menambahkan border dengan solid gray
                            borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                            //  backgroundColor: "gray", // Mengatur background color menjadi gray
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            // marginRight:"0.5rem"
                            backgroundColor: "#3983e0",
                        }}>
                            <PiChartPieSliceLight size={20} color="white" />
                        </div>
                    }
                >
                    Portfolio Risk Analysis
                </Menu.Item>
                <Menu.Item
                    // onClick={handleClickSolutionCurated}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="4"
                    icon={
                        <>
                            <div style={{
                                border: "1px solid white", // Menambahkan border dengan solid gray
                                borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                display: "flex", // Flexbox untuk mengatur konten
                                justifyContent: "center", // Horizontal alignment ke tengah
                                alignItems: "center", // Vertical alignment ke tengah
                                width: "35px", // Lebar div
                                height: "35px", // Tinggi div
                                marginRight: "0.5rem",
                                backgroundColor: "#3983e0",
                            }}>
                                <TbMapSearch size={20} color="white" />
                            </div>
                        </>
                    }>
                    Territory Planning
                </Menu.Item>
            </Flex>
            <Flex>

                <Menu.Item
                    // onClick={handleClickSolutionCurated}
                    style={{
                        backgroundColor: "white",
                        color: "gray",
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key="4"
                    icon={
                        <>
                            <div style={{
                                border: "1px solid white", // Menambahkan border dengan solid gray
                                borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                //    backgroundColor: "#3983e0", // Mengatur background color menjadi gray
                                display: "flex", // Flexbox untuk mengatur konten
                                justifyContent: "center", // Horizontal alignment ke tengah
                                alignItems: "center", // Vertical alignment ke tengah
                                width: "35px", // Lebar div
                                height: "35px", // Tinggi div
                                marginRight: "0.5rem",
                                backgroundColor: "#3983e0",
                            }}>
                                <TbSeeding size={20} color="white" />
                            </div>
                        </>
                    }>
                    Environmental Management
                </Menu.Item>
            </Flex>
            <Menu.Item
                style={{
                    backgroundColor: "white",
                    color: "#3983e0",
                    display: 'flex',
                    alignItems: 'center',
                }}
                key="4"
                icon={
                    <>
                        <div style={{
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            marginRight: "0rem"

                        }}>
                            <FaArrowRightLong size={20} color="#3983e0" />
                        </div>
                    </>
                }>
                All Use Cases
            </Menu.Item>
        </Menu>

    );

    const menuUser = (
        <Menu style={{
            width: "200px"
        }}>
            <Menu.Item
                style={{
                    backgroundColor: "white",
                    color: "gray",
                    display: 'flex',
                    alignItems: 'center',
                }}
                icon={
                    <>
                        <div style={{
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            marginRight: "0rem"

                        }}>
                            <CgProfile size={20} color="#3983e0" />
                        </div>
                    </>
                }>
                {user?.displayName}
            </Menu.Item>
            <Menu.Item

                onClick={handleToDashboard}
                style={{
                    backgroundColor: "white",
                    color: "gray",
                    display: 'flex',
                    alignItems: 'center',
                }}
                icon={
                    <>
                        <div style={{

                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            marginRight: "0rem"

                        }}>
                            <MdOutlineSpaceDashboard size={20} color="#3983e0" />
                        </div>
                    </>
                }>
                Dashboard
            </Menu.Item>
            <Menu.Item
                onClick={handleLogout}
                style={{
                    backgroundColor: "white",
                    color: "gray",
                    display: 'flex',
                    alignItems: 'center',
                }}
                icon={
                    <>
                        <div style={{
                            display: "flex", // Flexbox untuk mengatur konten
                            justifyContent: "center", // Horizontal alignment ke tengah
                            alignItems: "center", // Vertical alignment ke tengah
                            width: "35px", // Lebar div
                            height: "35px", // Tinggi div
                            marginRight: "0rem"

                        }}>
                            <IoLogOutOutline size={20} color="#3983e0" />
                        </div>
                    </>
                }>
                Logout
            </Menu.Item>
        </Menu>
    )

    return (
        <Flex align={"center"} justify={"between"} css={{
            position: "sticky",
            width: "87%",
            // width:'100%',
            "& svg": {
                fill: "$onBg"
            },
            "& svg[id=menu]": {
                display: "none",
                cursor: "pointer",
                fill: "$onBg800",
                "&:hover": {
                    fill: "$onBg",
                }
            },
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            ...style,
            "@bp2": {
                "& svg[id=menu]": {
                    display: "block"
                },
            },
            animation: `${fadeinTop} 1s `
        }}>
            <Flex
                onClick={handleClickHome}
                justify={"start"}
                align={"center"}
                css={{
                    cursor: "pointer",
                    marginLeft: "9.5rem",
                    "@bp2": {
                        marginLeft: "1rem",
                    },
                }}>
                <img
                    src={logogeosquare} // Ganti yourImageSrc dengan sumber gambar Anda
                    alt="GeoSquare.ai"
                    style={{ 
                        cursor: "pointer", 
                        width: windowWidth <= 768 ? "90px" : '124px',
                        height: "auto" 
                        }} />
            </Flex>

            <Flex
                justify={"end"}
                align="center"
                css={{
                    "@bp2": {
                        display: "none"
                    }
                }}>

                <Flex css={{
                    "& p": {
                        cursor: "pointer ",
                        padding: "0 $3",
                        color: "black",
                        "&:hover": {
                            color: "black"
                        }
                    },
                    marginRight: "2.5rem",
                    "@bp2": {
                        marginRight: "$3",
                    }
                }}>

                    <Flex
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        align="center"
                        style={{
                            marginRight: '30px',
                            marginLeft: '10px',
                            cursor: "pointer",
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 'bold'
                        }}
                        css={{
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                transition: '0.25s ease-in-out',
                            }
                        }}
                    >

                        <Popover
                            content={menuUseCase}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', fontFamily: '"Jakarta Sans", sans-serif', }}>
                                <IoLayersOutline size={24} style={{ marginRight: '8px' }} /> {/* Tambahkan ikon di sini */}
                                <span>Use Cases</span>
                                {isHovered ?
                                    <AiOutlineUp size={13} style={{ marginLeft: '8px', transition: 'transform 0.55s ease-in-out' }} />
                                    :
                                    <AiOutlineDown size={13}
                                        style={{ marginLeft: '8px', transition: 'transform 0.50s ease-in-out' }} />
                                }
                            </div>
                        </Popover>
                    </Flex>
                    <Flex
                        onMouseEnter={() => setIsHoveredSolution(true)}
                        onMouseLeave={() => setIsHoveredSolution(false)}
                        align="center"
                        style={{
                            marginRight: '30px',
                            marginLeft: '10px',
                            cursor: "pointer",
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 'bold'
                        }}
                        css={{
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                transition: '0.25s ease-in-out',
                            }
                        }}
                    >

                        <Popover
                            content={menu}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', fontFamily: '"Jakarta Sans", sans-serif', }}>
                                <IoRocketOutline size={24} style={{ marginRight: '8px' }} />
                                Solutions
                                {isHoveredSolution ?
                                    <AiOutlineUp size={13} style={{ marginLeft: '8px', transition: 'transform 0.55s ease-in-out' }} />
                                    :
                                    <AiOutlineDown size={13}
                                        style={{ marginLeft: '8px', transition: 'transform 0.50s ease-in-out' }} />
                                }
                            </div>

                        </Popover>
                    </Flex>
                    <Flex
                    onClick={handleClickApiDashboard}
                        align="center"
                        style={{
                            marginRight: '30px',
                            marginLeft: '10px',
                            cursor: "pointer",
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontFamily: '"Jakarta Sans", sans-serif',
                        }}
                        css={{
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                transition: '0.25s ease-in-out',
                            }
                        }}
                    >

                        <PiLinkSimpleBold size={24} style={{ marginRight: '8px' }} /> {/* Tambahkan ikon di sini */}
                        <span>Integration</span>
                    </Flex>
                    <Flex
                        align="center"
                        style={{
                            marginRight: '30px',
                            marginLeft: '10px',
                            cursor: "pointer",
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontFamily: '"Jakarta Sans", sans-serif',
                        }}
                        css={{
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                transition: '0.25s ease-in-out',
                            }
                        }}
                    >
                        <FaUsers size={24} style={{ marginRight: '8px' }} />
                        <span>About Us</span>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                justify={"end"}
                align="center"
                css={{
                    "@bp2": {
                        display: "none"
                    }
                }}>

                <Flex css={{
                    "& p": {
                        cursor: "pointer ",
                        padding: "0 $3",
                        color: "black",
                        "&:hover": {
                            color: "black"
                        }
                    },
                    marginRight: "7rem",
                    "@bp2": {
                        marginRight: "$3",
                    }
                }}>


                    <Flex
                        align="center"

                        css={{
                            // marginLeft: "2.5rem",
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                transition: '0.25s ease-in-out',
                            }
                        }}
                    >
                        {!user ?
                            <Text
                                onClick={handleClicklogin}
                                cursor={"click"}
                                style={{ color: "white" }}
                                css={{
                                    color: "white",
                                    backgroundColor: "#316DBA",
                                    headline6: "600",
                                    padding: "$1 $3",
                                    width: '100px',
                                    textAlign: "center",
                                    alignContent: "center",
                                    height: "36px",
                                    fontSize: '14px',
                                    borderRadius: "8px",
                                    fontFamily: '"Jakarta Sans", sans-serif',
                                    boxShadow: "$1dp",
                                    cursor:'pointer'
                                }}
                            >
                                Login
                            </Text>
                            :
                            <Popover content={menuUser}>
                                <Avatar style={{
                                    border: "1px solid #3983e0", // 
                                    cursor:'pointer'
                                }} size={40} src={avatarUrl} shape="square" />
                            </Popover>}
                    </Flex>
                </Flex>


            </Flex>

            <IoMenu color="white" size={24} style={{ margin: '1rem' }} id="menu" onClick={showDrawer} />

            <Drawer
                style={{ height: "auto" }}
                closable={false}
                placement="top"
                onClose={closeDrawer}
                visible={isDrawerVisible}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <img onClick={handleClickHome} src={logogeosquare} alt="GeoSquare.ai" style={{ width: '124px', height: 'auto' }} />
                    </div>
                    <div>
                        <Button type="text" onClick={closeDrawer}>
                            <IoCloseCircleOutline size={30} />
                        </Button>
                    </div>
                </div>
                <div style={{
                    textAlign: 'center'
                }}>
                    {!user ?
                        <Text
                            onClick={handleClicklogin}
                            cursor={"click"}
                            css={{
                                color: "white",
                                backgroundColor: "#316DBA",
                                headline6: "600",
                                padding: "$1 $3",
                                textAlign: "center",
                                alignContent: "center",
                                height: "3rem",
                                borderRadius: "8px",
                                boxShadow: "$1dp",
                                marginBottom: '1rem',
                                fontFamily: '"Jakarta Sans", sans-serif',
                            }}
                        >
                            Login
                        </Text>
                        :
                        <Popover content={menuUser}>
                            <Avatar style={{
                                border: "1px solid #3983e0", // 
                            }} size={40} src={avatarUrl} shape="square" />
                        </Popover>}
                    <Text css={{ padding: '1rem', color: 'Gray' }}>{user?.displayName}</Text>
                </div>
                <Menu mode="inline" style={{ overflowY: 'auto', maxHeight: '500px' }}>
                    <SubMenu key="sub1" title="Use Cases" style={{ overflowY: 'auto', maxHeight: '500px' }}>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="1"
                            icon={
                                <div style={{
                                    border: "1px solid white", // Menambahkan border dengan solid gray
                                    borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                    //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                    display: "flex", // Flexbox untuk mengatur konten
                                    justifyContent: "center", // Horizontal alignment ke tengah
                                    alignItems: "center", // Vertical alignment ke tengah
                                    width: "35px", // Lebar div
                                    height: "35px", // Tinggi div
                                    backgroundColor: "#3983e0",
                                }}>
                                    <AiOutlineSelect size={20} color="white" />
                                </div>
                            }
                        >
                            Site Selection
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="1"
                            icon={
                                <div style={{
                                    border: "1px solid white", // Menambahkan border dengan solid gray
                                    borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                    //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                    display: "flex", // Flexbox untuk mengatur konten
                                    justifyContent: "center", // Horizontal alignment ke tengah
                                    alignItems: "center", // Vertical alignment ke tengah
                                    width: "35px", // Lebar div
                                    height: "35px", // Tinggi div
                                    backgroundColor: "#3983e0",
                                    // marginRight:"0.5rem"
                                }}>
                                    <TbHeartRateMonitor size={20} color="white" />
                                </div>
                            }
                        >
                            Geomarketing
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="3"
                            icon={
                                <div style={{
                                    border: "1px solid white", // Menambahkan border dengan solid gray
                                    borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                    //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                    display: "flex", // Flexbox untuk mengatur konten
                                    justifyContent: "center", // Horizontal alignment ke tengah
                                    alignItems: "center", // Vertical alignment ke tengah
                                    width: "35px", // Lebar div
                                    height: "35px", // Tinggi div
                                    // marginRight:"0.5rem"
                                    backgroundColor: "#3983e0",
                                }}>
                                    <FiTruck size={20} color="white" />
                                </div>
                            }
                        >
                            Supply Chain Optimization
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="4"
                            icon={
                                <>
                                    <div style={{
                                        border: "1px solid white", // Menambahkan border dengan solid gray
                                        borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                        //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                        display: "flex", // Flexbox untuk mengatur konten
                                        justifyContent: "center", // Horizontal alignment ke tengah
                                        alignItems: "center", // Vertical alignment ke tengah
                                        width: "35px", // Lebar div
                                        height: "35px", // Tinggi div
                                        marginRight: "0.5rem",
                                        backgroundColor: "#3983e0",
                                    }}>
                                        <TbSmartHome size={20} color="white" />
                                    </div>
                                </>
                            }>
                            Out of Home Analytics
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="3"
                            icon={
                                <div style={{
                                    border: "1px solid white", // Menambahkan border dengan solid gray
                                    borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                    //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                    display: "flex", // Flexbox untuk mengatur konten
                                    justifyContent: "center", // Horizontal alignment ke tengah
                                    alignItems: "center", // Vertical alignment ke tengah
                                    width: "35px", // Lebar div
                                    height: "35px", // Tinggi div
                                    // marginRight:"0.5rem"
                                    backgroundColor: "#3983e0",
                                }}>
                                    <RiEarthquakeLine size={20} color="white" />
                                </div>
                            }
                        >
                            Catastrophe Modeling
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="4"
                            icon={
                                <>
                                    <div style={{
                                        border: "1px solid white", // Menambahkan border dengan solid gray
                                        borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                        //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                        display: "flex", // Flexbox untuk mengatur konten
                                        justifyContent: "center", // Horizontal alignment ke tengah
                                        alignItems: "center", // Vertical alignment ke tengah
                                        width: "35px", // Lebar div
                                        height: "35px", // Tinggi div
                                        marginRight: "0.5rem",
                                        backgroundColor: "#3983e0",
                                    }}>
                                        <TbDatabaseDollar size={20} color="white" />
                                    </div>
                                </>
                            }>
                            Data Monetization
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="3"
                            icon={
                                <div style={{
                                    border: "1px solid white", // Menambahkan border dengan solid gray
                                    borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                    //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                    display: "flex", // Flexbox untuk mengatur konten
                                    justifyContent: "center", // Horizontal alignment ke tengah
                                    alignItems: "center", // Vertical alignment ke tengah
                                    width: "35px", // Lebar div
                                    height: "35px", // Tinggi div
                                    backgroundColor: "#3983e0",
                                    // marginRight:"0.5rem"
                                }}>
                                    <LuMonitorX size={20} color="white" />
                                </div>
                            }
                        >
                            Fraud Detection
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="4"
                            icon={
                                <>
                                    <div style={{
                                        border: "1px solid white", // Menambahkan border dengan solid gray
                                        borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                        //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                        display: "flex", // Flexbox untuk mengatur konten
                                        justifyContent: "center", // Horizontal alignment ke tengah
                                        alignItems: "center", // Vertical alignment ke tengah
                                        width: "35px", // Lebar div
                                        height: "35px", // Tinggi div
                                        marginRight: "0.5rem",
                                        backgroundColor: "#3983e0",
                                    }}>
                                        <MdOutlineRealEstateAgent size={20} color="white" />
                                    </div>
                                </>
                            }>
                            Real Estate Market Analysis
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="3"
                            icon={
                                <div style={{
                                    border: "1px solid white", // Menambahkan border dengan solid gray
                                    borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                    //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                    display: "flex", // Flexbox untuk mengatur konten
                                    justifyContent: "center", // Horizontal alignment ke tengah
                                    alignItems: "center", // Vertical alignment ke tengah
                                    width: "35px", // Lebar div
                                    height: "35px", // Tinggi div
                                    backgroundColor: "#3983e0",
                                    // marginRight:"0.5rem"
                                }}>
                                    <MdOutlineHealthAndSafety size={20} color="white" />
                                </div>
                            }
                        >
                            Healthcare Analytics
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="4"
                            icon={
                                <>
                                    <div style={{
                                        border: "1px solid white", // Menambahkan border dengan solid gray
                                        borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                        //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                        display: "flex", // Flexbox untuk mengatur konten
                                        justifyContent: "center", // Horizontal alignment ke tengah
                                        alignItems: "center", // Vertical alignment ke tengah
                                        width: "35px", // Lebar div
                                        height: "35px", // Tinggi div
                                        marginRight: "0.5rem",
                                        backgroundColor: "#3983e0",
                                    }}>
                                        <LuSmartphoneNfc size={20} color="white" />
                                    </div>
                                </>
                            }>
                            IoT Analytics and Smart Cities
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="3"
                            icon={
                                <div style={{
                                    border: "1px solid white", // Menambahkan border dengan solid gray
                                    borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                    //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                    display: "flex", // Flexbox untuk mengatur konten
                                    justifyContent: "center", // Horizontal alignment ke tengah
                                    alignItems: "center", // Vertical alignment ke tengah
                                    width: "35px", // Lebar div
                                    height: "35px", // Tinggi div
                                    backgroundColor: "#3983e0",
                                    // marginRight:"0.5rem"
                                }}>
                                    <PiShareNetworkLight size={20} color="white" />
                                </div>
                            }
                        >
                            Network Deployment
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="4"
                            icon={
                                <>
                                    <div style={{
                                        border: "1px solid white", // Menambahkan border dengan solid gray
                                        borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                        //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                        display: "flex", // Flexbox untuk mengatur konten
                                        justifyContent: "center", // Horizontal alignment ke tengah
                                        alignItems: "center", // Vertical alignment ke tengah
                                        width: "35px", // Lebar div
                                        height: "35px", // Tinggi div
                                        marginRight: "0.5rem",
                                        backgroundColor: "#3983e0",
                                    }}>
                                        <FaArrowsDownToPeople size={20} color="white" />
                                    </div>
                                </>
                            }>
                            Mobility Planning
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="3"
                            icon={
                                <div style={{
                                    border: "1px solid white", // Menambahkan border dengan solid gray
                                    borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                    //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                    display: "flex", // Flexbox untuk mengatur konten
                                    justifyContent: "center", // Horizontal alignment ke tengah
                                    alignItems: "center", // Vertical alignment ke tengah
                                    width: "35px", // Lebar div
                                    height: "35px", // Tinggi div
                                    // marginRight:"0.5rem"
                                    backgroundColor: "#3983e0",
                                }}>
                                    <PiChartPieSliceLight size={20} color="white" />
                                </div>
                            }
                        >
                            Portfolio Risk Analysis
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="4"
                            icon={
                                <>
                                    <div style={{
                                        border: "1px solid white", // Menambahkan border dengan solid gray
                                        borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                        //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                        display: "flex", // Flexbox untuk mengatur konten
                                        justifyContent: "center", // Horizontal alignment ke tengah
                                        alignItems: "center", // Vertical alignment ke tengah
                                        width: "35px", // Lebar div
                                        height: "35px", // Tinggi div
                                        marginRight: "0.5rem",
                                        backgroundColor: "#3983e0",
                                    }}>
                                        <TbMapSearch size={20} color="white" />
                                    </div>
                                </>
                            }>
                            Territory Planning
                        </Menu.Item>
                        <Menu.Item

                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="4"
                            icon={
                                <>
                                    <div style={{
                                        border: "1px solid white", // Menambahkan border dengan solid gray
                                        borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                        //    backgroundColor: "#3983e0", // Mengatur background color menjadi gray
                                        display: "flex", // Flexbox untuk mengatur konten
                                        justifyContent: "center", // Horizontal alignment ke tengah
                                        alignItems: "center", // Vertical alignment ke tengah
                                        width: "35px", // Lebar div
                                        height: "35px", // Tinggi div
                                        marginRight: "0.5rem",
                                        backgroundColor: "#3983e0",
                                    }}>
                                        <TbSeeding size={20} color="white" />
                                    </div>
                                </>
                            }>
                            Environmental Management
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub2" title="Solution">
                        <Menu.Item
                            onClick={handleClickSolutionInsight}
                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="1"
                            icon={
                                <div style={{
                                    border: "1px solid #3983e0", // 
                                    borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                    //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                    display: "flex", // Flexbox untuk mengatur konten
                                    justifyContent: "center", // Horizontal alignment ke tengah
                                    alignItems: "center", // Vertical alignment ke tengah
                                    width: "35px", // Lebar div
                                    height: "35px", // Tinggi div
                                    backgroundColor: "#3983e0", // Mengatur background color menjadi 
                                }}>
                                    <VscGraph size={20} color="white" />
                                </div>
                            }
                        >
                            GeoSquare Insights
                        </Menu.Item>
                        <Menu.Item
                            onClick={handleClickSolutionSurvey}
                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="3"
                            icon={
                                <div style={{
                                    border: "1px solid #3983e0", // Menambahkan border dengan solid gray
                                    borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                    //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                    display: "flex", // Flexbox untuk mengatur konten
                                    justifyContent: "center", // Horizontal alignment ke tengah
                                    alignItems: "center", // Vertical alignment ke tengah
                                    width: "35px", // Lebar div
                                    height: "35px", // Tinggi div
                                    backgroundColor: "#3983e0", // Mengatur background color menjadi 
                                }}>
                                    <MdOutlineMiscellaneousServices size={20} color="white" />
                                </div>
                            }
                        >
                            On-Demand Services
                        </Menu.Item>
                        <Menu.Item
                            onClick={handleClickSolutionCurated}
                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="4"
                            icon={
                                <>
                                    <div style={{
                                        border: "1px solid white", // Menambahkan border dengan solid gray
                                        borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                        //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                        display: "flex", // Flexbox untuk mengatur konten
                                        justifyContent: "center", // Horizontal alignment ke tengah
                                        alignItems: "center", // Vertical alignment ke tengah
                                        width: "35px", // Lebar div
                                        height: "35px", // Tinggi div
                                        marginRight: "0.5rem",
                                        backgroundColor: "#3983e0", // Mengatur background color menjadi 
                                    }}>
                                        <LuMonitorSmartphone size={20} color="white" />
                                    </div>
                                </>
                            }>
                            Curated Platforms
                        </Menu.Item>
                        <Menu.Item
                            onClick={handleClickSolutionData}
                            style={{
                                backgroundColor: "white",
                                color: "gray",
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            key="1"
                            icon={
                                <div style={{
                                    border: "1px solid #3983e0", // Menambahkan border dengan solid gray
                                    borderRadius: "50%", // Membuat border radius 50% untuk efek lingkaran
                                    //  backgroundColor: "gray", // Mengatur background color menjadi gray
                                    display: "flex", // Flexbox untuk mengatur konten
                                    justifyContent: "center", // Horizontal alignment ke tengah
                                    alignItems: "center", // Vertical alignment ke tengah
                                    width: "35px", // Lebar div
                                    height: "35px", // Tinggi div
                                    backgroundColor: "#3983e0", // Mengatur background color menjadi 
                                }}>
                                    <AiFillDatabase size={20} color="white" />
                                </div>
                            }
                        >
                            Analysis-Ready Dataset
                        </Menu.Item>
                    </SubMenu>

                </Menu>
            </Drawer>
        </Flex>
    )
}
