// import React from 'react';
// import { convertToKm2 } from '../../../component/newmap/helper/formatNumber';
// import icAddress from '../../../asesst/Icons/interactivemap/icon/result.svg';
// import icPotition from '../../../asesst/Icons/interactivemap/icon/result3.svg';
// import icArea from '../../../asesst/Icons/interactivemap/icon/result2.svg';
// import icDownload from '../../../asesst/Icons/interactivemap/icon/ic-download.svg';
// import { Button, notification } from 'antd';
// import useReduxCatalog from '../../../hoc/hooks/useCatalog';
// interface ResultCatalogProps {
//   onBackClick: () => void;
//   metaData: any
// }

// export const DrawerSeeData: React.FC<ResultCatalogProps> = ({ onBackClick, metaData }) => {
//   const {
//     setDownloadData,
//     downloadData
//   } = useReduxCatalog();

//   const handleDownloadClick = async (data: any) => {
//     try {
//       await setDownloadData({
//         url: data.urls,
//       });

//       if (downloadData) {
//         window.open(downloadData?.url, '_blank');
//       }

//     } catch (error) {
//       notification.error({
//         style: {
//           backgroundColor: '#333939',
//           color: 'white',  // Make the text white
//         },
//         message: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed</p>,
//         description: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed to download data. Please try again latter</p>,
//         placement: 'topRight',
//         closeIcon: <span style={{ color: 'white' }}>×</span>
//       });
//     }
//   }
//   return (
//     <React.Fragment>
//       {/* Back Arrow */}
//       <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
//         <span onClick={onBackClick} style={{ fontSize: '18px', fontWeight: 'bold' }}>← Result Location</span>
//       </div>

//       <div
//         style={{
//           alignItems: 'center',
//           background: '#141617',
//           padding: '5px',
//           borderRadius: '12px',
//           marginBottom: '5px',
//           paddingTop: '10px',
//           paddingBottom: '10px',
//           border: '1px solid black', // Set border color to white
//           paddingLeft: '10px',
//           paddingRight: '10px',
//           cursor: 'pointer',
//           transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for 
//         }}
//       >
//         <div style={{ width: "166px" }}>
//           <h4 style={{ marginBottom: '10px', fontSize: '12px' }}>{metaData?.alias}</h4>
//         </div>
//         <div>
//           <p style={{ fontSize: '11px' }}> {metaData?.description}</p>
//         </div>
//       </div>
//       <div
//         style={{
//           // display: 'flex',
//           alignItems: 'center',
//           background: '#141617',
//           padding: '5px',
//           borderRadius: '12px',
//           marginBottom: '5px',
//           paddingTop: '10px',
//           paddingBottom: '10px',
//           border: '1px solid black', // Set border color to white
//           paddingLeft: '10px',
//           paddingRight: '10px',
//           cursor: 'pointer',
//           transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for 
//         }}
//       >
//         <div style={{ marginBottom: '15px' }}>
//           <img
//             alt="example"
//             src={icAddress}
//             style={{ marginRight: '10px' }}
//           />
//           <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Location Address</span>
//           <p style={{ margin: '5px 0 0', fontSize: '11px' }}>
//             {metaData?.address}
//           </p>
//         </div>

//         <div style={{ marginBottom: '15px' }}>
//           <img
//             alt="example"
//             src={icPotition}
//             style={{ marginRight: '10px' }}
//           />
//           <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Position</span>
//           <p style={{ margin: '5px 0 0', fontSize: '11px' }}>
//             {metaData?.centroid[1]}, {metaData?.centroid[0]}
//           </p>
//         </div>


//         <div>
//           <img
//             alt="example"
//             src={icArea}
//             style={{ marginRight: '10px' }}
//           />
//           <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Size of Area</span>
//           <p style={{ margin: '5px 0 0', fontSize: '11px' }}>
//             {convertToKm2(metaData?.area)} km²
//           </p>
//         </div>


//       </div>
//       <div
//         style={{
//           alignItems: 'center',
//           background: '#141617',
//           padding: '5px',
//           borderRadius: '12px',
//           marginBottom: '5px',
//           paddingTop: '10px',
//           paddingBottom: '10px',
//           border: '1px solid black', // Set border color to white
//           paddingLeft: '10px',
//           paddingRight: '10px',
//           cursor: 'pointer',
//           transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for 
//         }}
//       >
//         <p style={{ color: 'white', marginBottom: '10px', textAlign: 'left', fontSize: '14px', fontWeight: 'bold' }}>Download Data</p>

//         {metaData?.other_format && typeof metaData.other_format === 'object' ? (
//   Object.entries(metaData.other_format).map(([type, data]) => (
//     <Button
//       key={type}
//       type="primary"
//       style={{
//         height: '30px',
//         width: '100%',
//         borderRadius: '8px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         fontSize: '14px',
//         fontWeight: 700,
//         marginBottom: '10px',
//         background: 'transparent',
//         color: '#4A7FC0',
//         border: '1px solid #4A7FC0',
//         transition: 'transform 0.3s ease, box-shadow 0.3s ease',
//       }}
//       onClick={() => handleDownloadClick(data)}
//     >
//       <img
//         alt="example"
//         src={icDownload}
//         style={{ marginRight: '10px' }}
//       />
//       {type}
//     </Button>
//   ))
// ) : (
//   <p style={{ color: 'white', textAlign: 'center', fontSize: '14px' }}>
//     No available formats for download.
//   </p>
// )}

//       </div>



//     </React.Fragment>
//   );
// };


import React, { useEffect, useState } from 'react';
import {  Button, notification } from 'antd';
import { convertToKm2 } from '../../../component/newmap/helper/formatNumber';
import icAddress from '../../../asesst/Icons/interactivemap/icon/result.svg';
import icPotition from '../../../asesst/Icons/interactivemap/icon/result3.svg';
import icArea from '../../../asesst/Icons/interactivemap/icon/result2.svg';
import icDownload from '../../../asesst/Icons/interactivemap/icon/ic-download.svg';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';

interface ResultCatalogProps {
  onBackClick: () => void;
}

export const DrawerSeeData: React.FC<ResultCatalogProps> = ({ onBackClick }) => {
  const { setDownloadData, downloadData,metaData } = useReduxCatalog();

  // State untuk loading indikator
  const [isLoading, setIsLoading] = useState(true);

  // Monitor perubahan metaData
  useEffect(() => {
    // Cek apakah data sudah ada
    if (metaData?.other_format && typeof metaData.other_format === 'object') {
      setIsLoading(false); // Matikan loading
    }
  }, [metaData]);


  const handleDownloadClick = async (data: any) => {
    try {
      await setDownloadData({
        url: data.urls,
      });

      if (downloadData) {
        window.open(downloadData?.url, '_blank');
      }
    } catch (error) {
      notification.error({
        style: {
          backgroundColor: '#333939',
          color: 'white', // Make the text white
        },
        message: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed</p>,
        description: (
          <p style={{ color: 'white', fontWeight: 'bold' }}>
            Failed to download data. Please try again later.
          </p>
        ),
        placement: 'topRight',
        closeIcon: <span style={{ color: 'white' }}>×</span>,
      });
    }
  };

  return (
    <React.Fragment>
      {/* Back Arrow */}
      <div style={{ marginBottom: '20px', cursor: 'pointer' }}>
        <span onClick={onBackClick} style={{ fontSize: '18px', fontWeight: 'bold' }}>
          ← Result Location
        </span>
      </div>

      <div
        style={{
          alignItems: 'center',
          background: '#141617',
          padding: '5px',
          borderRadius: '12px',
          marginBottom: '5px',
          paddingTop: '10px',
          paddingBottom: '10px',
          border: '1px solid black', // Set border color to white
          paddingLeft: '10px',
          paddingRight: '10px',
          cursor: 'pointer',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
        }}
      >
        <div style={{ width: '166px' }}>
          <h4 style={{ marginBottom: '10px', fontSize: '12px' }}>{metaData?.alias}</h4>
        </div>
        <div>
          <p style={{ fontSize: '11px' }}> {metaData?.description}</p>
        </div>
      </div>

      <div
        style={{
          alignItems: 'center',
          background: '#141617',
          padding: '5px',
          borderRadius: '12px',
          marginBottom: '5px',
          paddingTop: '10px',
          paddingBottom: '10px',
          border: '1px solid black', // Set border color to white
          paddingLeft: '10px',
          paddingRight: '10px',
          cursor: 'pointer',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
        }}
      >
        <div style={{ marginBottom: '15px' }}>
          <img alt="example" src={icAddress} style={{ marginRight: '10px' }} />
          <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Location Address</span>
          <p style={{ margin: '5px 0 0', fontSize: '11px' }}>{metaData?.address}</p>
        </div>

        <div style={{ marginBottom: '15px' }}>
          <img alt="example" src={icPotition} style={{ marginRight: '10px' }} />
          <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Position</span>
          <p style={{ margin: '5px 0 0', fontSize: '11px' }}>
            {metaData?.centroid[1]}, {metaData?.centroid[0]}
          </p>
        </div>

        <div>
          <img alt="example" src={icArea} style={{ marginRight: '10px' }} />
          <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Size of Area</span>
          <p style={{ margin: '5px 0 0', fontSize: '11px' }}>{convertToKm2(metaData?.area)} km²</p>
        </div>
      </div>

      <div
        style={{
          alignItems: 'center',
          background: '#141617',
          padding: '5px',
          borderRadius: '12px',
          marginBottom: '5px',
          paddingTop: '10px',
          paddingBottom: '10px',
          border: '1px solid black', // Set border color to white
          paddingLeft: '10px',
          paddingRight: '10px',
          cursor: 'pointer',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Added transition for
        }}
      >
        <p style={{ color: 'white', marginBottom: '10px', textAlign: 'left', fontSize: '14px', fontWeight: 'bold' }}>
          Download Data
        </p>

     
        {isLoading ? (
        <div style={{ textAlign: 'center', padding: '10px' }}>
          <p style={{ color: 'white', fontSize: '14px' }}>Loading...</p>
        </div>
      ) : metaData?.other_format && typeof metaData.other_format === 'object' ? (
        Object.entries(metaData.other_format).map(([type, data]) => (
          <Button
            key={type}
            type="primary"
            style={{
              height: '30px',
              width: '100%',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: 700,
              marginBottom: '10px',
              background: 'transparent',
              color: '#4A7FC0',
              border: '1px solid #4A7FC0',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
            onClick={() => handleDownloadClick(data)}
          >
            <img alt="example" src={icDownload} style={{ marginRight: '10px' }} />
            {type}
          </Button>
        ))
      ) : (
        <p style={{ color: 'white', textAlign: 'center', fontSize: '14px' }}>
          No available formats for download. Try again later.
        </p>
      )}
      </div>
    </React.Fragment>
  );
};
