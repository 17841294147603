import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin, notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { EnvironmentOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import icAreaSize from '../../../asesst/Icons/interactivemap/icon/scale-card.svg';
import icGridTotal from '../../../asesst/Icons/interactivemap/icon/grid-card.svg';
// import icChart from '../../../asesst/Icons/interactivemap/icon/cart.svg';
import useReduxCatalog from '../../../hoc/hooks/useCatalog';
import Text from '../../../style/styledComponent/Text';
import { LoadingOutlined } from '@ant-design/icons';
import {
    auth,
} from '../../../firebase/firebaseConfig';
import useReduxPayment from '../../../hoc/hooks/usePayment';
import { FaRegClock } from 'react-icons/fa';
import { formatDateOnlyMonth } from '../../../component/newmap/helper/date';
import { formatNumberWithDots } from '../../../component/newmap/helper/formatNumber';

interface CheckoutInterface {
    uid: string;
    name: string;
    email: string;
    number: string;
    order_id: string;
    dataItems: GroupedData[];
    type: string;
    status_payment: string;
    status_data: string;
    output_status: string;
    created_at: string;
    deleted_at: string;
    updated_at: string;
    externalId: string;
    payerEmail: string;
    description: string;
    currency: string;
    invoiceDuration: number;
}

// transform data
interface Subdata {
    subdata: string;
    alias: string;
}

interface Additional {
    size: number | null;
}

interface Item {
    polygon_id: string;
    area: number;
    product: string;
    additional: Additional;
    created_at: string;
    subdata: Subdata[];
    total_grid: number;
    id: string;
    alias: string;
    currency: string;
    address: string;
    type: string;
    type_data: string;
    totalPrice: number;
    centroid: []

}

interface GroupedData {
    polygon_id: string;
    dataIds: Omit<Item, 'polygon_id' | 'total_grid' | 'area' | 'address' | 'centroid' | 'id' | 'currency' | 'created_at' | 'alias' | 'type' | 'type_data' | 'totalPrice'>[];
    total_grid: number;
    area: number;
    address: string;
    centroid: []
}
// transform data
export const ModalCart: React.FC<any> = ({ isModal }) => {
    const { setModalCloseCart, setCartCheckout } = useReduxCatalog();
    const [paymentCheckout, setPaymentCheckout] = useState<CheckoutInterface | null>(null);
    const [loading, setLoading] = useState(false);
    const { setPaymentv2 } = useReduxPayment();
    const handleCancel = () => {
        setModalCloseCart(true)
    };
    const calculateTotalPrice = (items: any[]): number => {
        return items?.reduce((total, item) => total + item.totalPrice, 0);
    };
    const totalPrice = calculateTotalPrice(setCartCheckout);

    const user = auth.currentUser;
    function groupData(data: Item[]): GroupedData[] {
        const grouped: Record<string, GroupedData> = {};

        data?.forEach(item => {
            // Destructure to exclude unwanted fields
            const { polygon_id, total_grid, area, address, centroid, id, currency, created_at, alias, type, type_data, totalPrice, ...rest } = item;

            if (!grouped[polygon_id]) {
                grouped[polygon_id] = {
                    polygon_id,
                    total_grid,
                    area,
                    centroid,
                    address,
                    dataIds: []
                };
            }
            grouped[polygon_id].dataIds.push(rest);
        });
        return Object.values(grouped);
    }
    // checkout payment
    useEffect(() => {
        const uuid = uuidv4();
        const modifiedUuid = uuid.toUpperCase().replace('-', ''); // Hapus tanda '-' yang pertama
        const invUid = modifiedUuid.slice(0, 12)
        const now = new Date();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Get current month (add 1 because getMonth() returns 0-11)
        const year = now.getFullYear(); // Get current year
        const invoiceId = `INV-GE0-${invUid}-${month}-${year}`;
        const result: GroupedData[] = groupData(setCartCheckout);
        setPaymentCheckout({
            uid: user?.uid || "",
            name: user?.displayName || "",
            email: user?.email || "",
            number: `${user?.phoneNumber || 0}`,
            order_id: invoiceId,
            dataItems: result, // Ensure it's an array
            type: "",
            status_payment: "waiting",
            status_data: "waiting",
            output_status: "available",
            created_at: now.toISOString(),
            deleted_at: "",
            updated_at: "",
            externalId: invoiceId,
            payerEmail: user?.email || "",
            description: "purchasing data",
            currency: "IDR",
            invoiceDuration: 86400, // Assuming this is correct
        });

    }, [setCartCheckout, user]);
    // end checkout payment
    const handleSubmit = async () => {
        setLoading(true); // Set loading to true
        try {
            const response = await setPaymentv2(paymentCheckout);
            if (response && response.success) {
                if( response.data === null){
                    handleCancel(); // Close the modal
                    notification.success({
                        style: {
                            backgroundColor: '#142943',
                            color: 'white'  // Menjadikan teks berwarna putih
                        },
                        message:  <p style={{color:'white',fontWeight:'bold'}}>Successful</p> ,
                        description: <p style={{color:'white', fontWeight:'bold'}}>Successfully Please check your DATA for further actions.</p>,
                        placement: 'topRight',
                    });
                } else {
                    window.open(response.data, '_blank'); // Open invoice in a new tab
                    localStorage.setItem('invoiceId', response?.invoice_id); // Store invoice ID
                    window.dispatchEvent(new Event('storage')); // Memaksa event storage agar dipicu di halaman lain
                }
            }
        } catch (error) {
            console.error('Failed to checkout data');
            notification.error({
                style: {
                    backgroundColor: '#142943',
                    color: 'white',  // Make the text white
                },
                message: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed</p>,
                description: <p style={{ color: 'white', fontWeight: 'bold' }}>Failed to checkout data. Please try again</p>,
                placement: 'topRight',
                closeIcon: <span style={{ color: 'white' }}>×</span>
            });
        } finally {
            setLoading(false); // Set loading to false after the operation completes
        }
    };

    return (
        <Modal
            onCancel={handleCancel}
            styles={{
                content: {
                    backgroundColor: '#333939'
                },
                body: {
                    backgroundColor: '#333939',
                    color: 'white'
                },
            }}
            open={isModal}
            width={400}
            footer={false}
            style={{ top: '3%' }}
            cancelText="Cancel"
            closeIcon={<CloseOutlined style={{ color: 'white' }} />} // Set the close icon color to white
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', height: '690px', }}>
                <div style={{ flex: 1 }}>
                    <span style={{
                        fontSize: '24px',
                        // marginBottom: '5px',
                        textAlign: 'left',
                        display: 'block',
                        width: '100%',
                        fontWeight: 'bold'
                    }}>Checkout</span>
                    <span style={{
                        fontSize: '17px',
                        marginBottom: '5px',
                        textAlign: 'left',
                        display: 'block',
                        width: '100%',
                        fontWeight: 'bold'
                    }}>Item</span>
                    <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
                        {setCartCheckout?.map((item: any) => (
                            <div style={{ display: 'flex', paddingLeft: '5px' }} key={item?.id}>
                                <div key={item?.id}>
                                    <div
                                        key={item?.id}
                                        style={{
                                            border: '1px solid white',
                                            background: '#141617',
                                            paddingLeft: '15px',
                                            paddingTop: '10px',
                                            width: '350px',
                                            height: '150px',
                                            borderRadius: '12px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <div style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', }}>
                                                    {item?.alias || 'No Data'}
                                                    <div
                                                        style={{
                                                            background: 'white',
                                                            padding: '5px',
                                                            // width: '40px',
                                                            height: '20px',
                                                            borderRadius: '12px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Text style={{ color: '#316DBA', margin: 0, fontSize: '10px' }}>50x50</Text>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                    <div style={{ display: 'flex', gap: '5px', marginTop: '10px', marginBottom: '5px' }}>
                                                        <img  style={{margin:'0px'}} alt="example" src={icAreaSize} />
                                                        <Text style={{ color: 'white', margin: 0, fontSize: '11px' }}>{formatNumberWithDots(item.area)} m2</Text>
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '5px', marginTop: '10px', marginBottom: '5px' }}>
                                                        <img style={{ margin: '0px', height:'15px', }} alt="example" src={icGridTotal} />
                                                        <Text style={{ color: 'white', margin: 0, fontSize: '11px' }}>{formatNumberWithDots(item.total_grid)}</Text>
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '5px', marginTop: '10px', marginBottom: '5px' }}>
                                                        <FaRegClock style={{ color: 'white' }} />
                                                        <Text style={{ color: 'white', margin: 0, fontSize: '11px' }}>{formatDateOnlyMonth(item.created_at)}</Text>
                                                    </div>

                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'left', gap: '10px', marginBottom: '5px', marginTop: '3px' }}>
                                                    <EnvironmentOutlined style={{ fontSize: '18px' }} />
                                                    <Text style={{ color: 'lightgray', margin: 0, fontSize: '12px' }}>{item.address || 'No Address'}</Text>
                                                </div>

                                                <h3 style={{ fontWeight: 'bold', color: 'white', margin: 0 }}>{item.totalPrice.toLocaleString('id-ID', {
                                                    style: 'currency',
                                                    currency: 'IDR',
                                                })}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            <div
                style={{
                    bottom: 30,
                    width: '350px',
                    position: 'absolute',
                    borderRadius: '10px',
                }}
            >
                <div style={{ color: 'white' }}>SubTotal</div>
                <div style={{ marginBottom: '5px', color: 'white' }}>
                    {setCartCheckout?.length} Items for {totalPrice?.toLocaleString('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                    })}
                </div>
                {loading ? (
                    <Button type="primary"
                        style={{
                            height: '48px',
                            width: '100%',
                            color: 'white',
                            backgroundColor: '#4A7FC0',
                        }}>
                        <Spin indicator={<LoadingOutlined spin />} size="small" style={{ color: 'white' }} />
                    </Button>
                ) : (
                    <Button
                        onClick={handleSubmit}
                        type="primary"
                        style={{
                            width: '100%',
                            height: '48px',
                            borderRadius: '8px',
                            backgroundColor: '#4A7FC0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '16px',
                            fontWeight: 700,
                        }}
                    >
                        Buy
                    </Button>
                )}

            </div>
        </Modal>
    )
}
