import React, { useEffect, useState } from "react";
import { Card, Typography, Button, Row, Col } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import analys from '../../../../asesst/new/img/analys.svg';
import ondemand from '../../../../asesst/new/img/ondemand.svg';
import satel from '../../../../asesst/new/img/satel.svg';
import locationprofile from '../../../../asesst/new/img/locationprofile.svg';
import spatialInsight from '../../../../asesst/new/img/spatialinsight.svg';
import curated from '../../../../asesst/new/img/curatedplatform.svg';
import custom from '../../../../asesst/new/img/customsolution.svg';
import servicesIcon from '../../../../asesst/new/icon/services.svg';
import Icon1 from '../../../../asesst/new/icon/dbic.svg';
import Icon2 from '../../../../asesst/new/icon/satelite.svg';
import Icon3 from '../../../../asesst/new/icon/layeric.svg';
import Icon4 from '../../../../asesst/new/icon/earth.svg'
import Icon5 from '../../../../asesst/new/icon/search.svg';
import Icon6 from '../../../../asesst/new/icon/poi.svg';
import Icon7 from '../../../../asesst/new/icon/retangle.svg';
import subIcon1 from '../../../../asesst/new/icon/landsurvey.svg'
import subIcon2 from '../../../../asesst/new/icon/water.svg'
import subIcon3 from '../../../../asesst/new/icon/carbon_drone.svg'
import subIcon4 from '../../../../asesst/new/icon/engsurvey.svg'
import subIcon5 from '../../../../asesst/new/icon/fieldSurvey.svg'
import subIconlist from '../../../../asesst/new/icon/Ellipse 1.svg'
const { Title, Text } = Typography;

function SectionServices2() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const data = [
        {
            img: ondemand,
            icon: Icon1,
            title: "On-Demand Data Collections",
            description: "Our on-demand data collection services offer precise and reliable data across various domains :",
            method: [
                {
                    iconMethod: subIcon1,
                    title: "Land Surveying",
                    description: ''
                },
                {
                    iconMethod: subIcon2,
                    title: "Water Surveying",
                    description: ''
                },
                {
                    iconMethod: subIcon3,
                    title: "Aerial Surveying",
                    description: ''
                },
                {
                    iconMethod: subIcon4,
                    title: "Engineering Surveying",
                    description: ''
                },
                {
                    iconMethod: subIcon5,
                    title: "Field Surveying",
                    description: ''
                }
            ]
        },
        {
            img: satel,
            icon: Icon2,
            title: "Satellite Images",
            description:
                "Access high-resolution visual data that provides a clear and intuitive understanding of the spatial context. Our satellite imagery offers detailed views of the Earth's surface, aiding in monitoring, analysis, and decision-making processes across various sectors.",
            method: []
        },
        {
            img: analys,
            icon: Icon3,
            title: "Analysis- Ready Dataset",
            description:
                "Benefit from raw, well-organized geospatial data that is ready for analysis. Our datasets are meticulously prepared to ensure they are accurate, comprehensive, and easy to integrate into your geospatial analyses and workflows.",
            method: []

        },
        {
            img: spatialInsight,
            icon: Icon4,
            title: "Spatial Insight",
            description:
                "Leverage processed and analyzed data that provides actionable insights. Our spatial insights transform raw geospatial data into meaningful information, helping you uncover patterns, trends, and opportunities for better decision-making.",
            method: []

        },
        {
            img: locationprofile,
            icon: Icon5,
            title: "Location Scores",
            description:
                "Utilize quantitative scores that facilitate decision-making based on specific criteria. Our location scores offer a numerical representation of various factors, such as suitability, risk, and potential, enabling you to compare and evaluate different locations effectively.",
            method: []

        },
        {
            img: curated,
            icon: Icon6,
            title: "Curated GIS Platform",
            description:
                "Access the best in geospatial technology through our curated GIS platform. We offer top-tier solutions from industry leaders like MAPID, all integrated under the GeoSquare.ai umbrella for your convenience, ensuring you have the tools you need to achieve geospatial excellence.",
            method: []

        },
        {
            img: custom,
            icon: Icon7,
            title: "Custom Solution",
            description:
                "Tailor our offerings to meet your specific needs with our custom solutions.",
            method: [
                {
                    iconMethod: subIconlist,
                    title: "Consultation",
                    description: ''
                },
                {
                    iconMethod: subIconlist,
                    title: "Customized Model & Scoring",
                    description: ''
                },
                {
                    iconMethod: subIconlist,
                    title: "Personalized GIS Platform",
                    description: ''
                }

            ]

        },
    ];

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const visibleCards = windowWidth <= 768 ? 1 : 4;

    const extendedData = [
        ...data.slice(-visibleCards),
        ...data,
        ...data.slice(0, visibleCards),
    ];

    const totalSlides = data.length + visibleCards * 2;

    const handleNext = () => {
        setCurrentSlide((prev) => prev + 1);
    };

    const handlePrev = () => {
        setCurrentSlide((prev) => prev - 1);
    };

    // Auto-slide functionality
    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    // Loop to the beginning or end of the data
    useEffect(() => {
        if (currentSlide >= totalSlides - visibleCards) {
            setTimeout(() => {
                setCurrentSlide(visibleCards);
            }, 500); // Smooth transition
        } else if (currentSlide < visibleCards) {
            setTimeout(() => {
                setCurrentSlide(totalSlides - visibleCards * 2);
            }, 500);
        }
    }, [currentSlide, totalSlides, visibleCards]);
    const headerStyles: React.CSSProperties = {
        padding: "1rem 10rem", // Adjust padding for small vs large screens
        textAlign: 'left',
        width: '100%',
    };
    const integrationText: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: windowWidth <= 768 ? 'center' : 'flex-start',
        flexDirection: windowWidth <= 768 ? 'column' : 'row',
        textAlign: windowWidth <= 768 ? 'center' : 'left',
    };
    const titleStyle: React.CSSProperties = {
        fontWeight: '800',
        fontSize: '38px',
        width: '900px',
        color: ' #333333',
        marginTop: '10px',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        textAlign: 'left',
    }
    const listItem: React.CSSProperties = {
        placeSelf: windowWidth <= 768 ? 'center' : 'flex-start',
        display: 'flex',
        alignContent: 'center',
        padding: '5px 0',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        listStyle: 'none',
        color: 'none',
    }

    const menuItemIcon: React.CSSProperties = {
        marginRight: windowWidth <= 768 ? '10px' : '5px',
        flexShrink: 0,
    }

    const listText: React.CSSProperties = {
        fontSize: '14px',
        fontWeight: '700',
        color: '#5A5A5A',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
    }
    const listSubDescription: React.CSSProperties = {
        fontSize: '14px',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        lineHeight: '10px',
        fontWeight: '400',
        color: '#5A5A5A',
        marginLeft: '20px',
        textAlign: windowWidth <= 768 ? 'center' : 'left',
    }
    return (
        <div style={{
            //  padding: "100px",
            marginTop:"120px",
            marginBottom:"120px",
             backgroundColor: "#fff",
             width: "100%",
             height: "auto",
              }}>
            <div style={headerStyles}>
                <Row justify="start" align="middle">
                    <Col xs={24} md={22}>
                        <div style={integrationText}>
                            <img alt="example" src={servicesIcon} style={{
                                marginRight: windowWidth <= 768 ? '' : '10px',
                                marginBottom: windowWidth <= 768 ? '10px' : '0',
                                color: '#316DBA',
                            }} />
                            <p style={{
                                fontFamily: "'Plus Jakarta Sans', sans-serif",
                                color: '#316DBA', fontSize: '24px', fontWeight: 'bolder'
                            }}>Services</p>
                        </div>
                        <Title style={titleStyle}>Geospatial Made Easy!</Title>
                        
                        <div 
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between', // Memisahkan teks dan tombol ke sisi yang berlawanan
                                alignItems: 'center', // Menjaga elemen tetap sejajar secara vertikal
                                gap: '10px',
                                marginTop: '20px',
                            }}
                        >
                            {/* Teks di sebelah kiri */}
                            <Text 
                                style={{
                                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                                    fontSize: '16px', 
                                    color: '#595959', 
                                    display: 'block',
                                    fontWeight: '400', 
                                    lineHeight: '1.5', 
                                    textAlign: 'left',
                                    flex: 1, // Memastikan teks menggunakan ruang yang tersedia
                                    maxWidth: '900px', // Membatasi panjang teks
                                    wordBreak: 'break-word', // Memastikan kata panjang terpecah
                                }}
                            >
                                From data collection to actionable insights, we cover the entire geospatial value chain, ensuring you have everything needed from data acquisition to decision-making.
                            </Text>

                            {/* Tombol di sebelah kanan */}
                            <div 
                                style={{
                                    display: 'flex',
                                    gap: '10px', // Jarak antar tombol
                                }}
                            >
                                <Button
                                    shape="circle"
                                    icon={<LeftOutlined  />}
                                    onClick={handlePrev}
                                    style={{
                                        backgroundColor: "#fff",
                                        color: "#316DBA",
                                        height: "46px",
                                        width: "46px",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    }}
                                />
                                <Button
                                    shape="circle"
                                    icon={<RightOutlined />}
                                    onClick={handleNext}
                                    style={{
                                        backgroundColor: "#fff",
                                        color: "#316DBA",
                                        height: "46px",
                                        width: "46px",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    }}
                                />
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                }}
            >
                
                <div
                    style={{
                        display: "flex",
                        overflow: "hidden",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            margin:'10px',
                            display: "flex",
                            transform: `translateX(-${(currentSlide * 100) / visibleCards
                                }%)`,
                            transition: "transform 0.5s ease",
                            gap: "20px",
                        }}
                    >
                        {extendedData.map((item, index) => (
                            <Card
                                key={index}
                                style={{
                                    minWidth: `${100 / visibleCards}%`,
                                    border: "none",
                                    backgroundColor: "#fff",
                                    borderRadius: "10px",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                }}
                                bodyStyle={{ padding: "20px" }}
                                cover={
                                    <img
                                        alt={item.title}
                                        src={item.img}
                                        style={{
                                            borderRadius: "10px 10px 0 0",
                                            height: "220px",
                                            padding:'12px',
                                            objectFit: "cover",
                                        }}
                                    />
                                }
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: windowWidth <= 768 ? "column" : "row",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <img
                                        alt="example"
                                        src={item.icon}
                                        style={{
                                            marginRight: windowWidth <= 768 ? "0" : "10px",
                                            marginBottom: windowWidth <= 768 ? "10px" : "0",
                                            width: "24px", // Ubah ukuran sesuai kebutuhan
                                            height: "24px", // Ubah ukuran sesuai kebutuhan
                                            color: "#316DBA",
                                        }}
                                    />
                                    <Title
                                        level={4}
                                        style={{
                                            fontFamily: "'Plus Jakarta Sans', sans-serif",
                                            fontSize:'16px',
                                            color: "#316DBA",
                                            margin: 0, // Hapus margin default agar lebih rapi
                                            textAlign: windowWidth <= 768 ? "center" : "left",
                                        }}
                                    >
                                        {item.title}
                                    </Title>
                                </div>
                                <Text style={{ fontFamily: "'Plus Jakarta Sans', sans-serif", fontSize: "14px", color: "#595959" }}>
                                    {item.description}
                                </Text>
                                {item.method.map((meth, index) => (
                                    typeof meth === 'object' && meth.title ? (
                                        <li key={index} style={listItem}>
                                            <Row justify="start" align="middle">
                                                <img
                                                    src={meth.iconMethod}
                                                    alt="icon method"
                                                    style={{
                                                        marginRight: '10px',
                                                        color: '#316DBA',
                                                        ...menuItemIcon,
                                                    }}
                                                />
                                                <Text style={listText}>{meth.title}</Text>
                                                <Text style={listSubDescription}>{meth.description}</Text>
                                            </Row>

                                        </li>
                                    ) : null
                                ))}
                            </Card>
                        ))}
                    </div>
                </div>
                
            </div>
            {/* Dots Navigation */}
            <div style={{ textAlign: "center", marginTop: "30px" }}>
                {data.map((_, index) => (
                    <span
                        key={index}
                        onClick={() => setCurrentSlide(index + visibleCards)}
                        style={{
                            display: "inline-block",
                            width: currentSlide === index + visibleCards ? "100px" : "12px",
                            height: "12px",
                            margin: "0 5px",
                            backgroundColor:
                                currentSlide === index + visibleCards ? "#316DBA" : "#92B2D9",
                            borderRadius:
                                currentSlide === index + visibleCards ? "25px" : "50%",
                            cursor: "pointer",
                            transition: "background-color 0.3s",
                        }}
                    ></span>
                ))}
            </div>
        </div>
    );
}

export default SectionServices2;
